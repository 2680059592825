import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router';

import { EventCard } from '../Cards/EventCard';
import { UserContext } from 'Store/UserContext';
import { getValueByPropPath } from 'Utils/objectHelpers';
import { AddOnRows } from './AddOnRows';
import RequiredAddOns from './RequiredAddOns';
import { VENUE_ADD_ONS } from 'Queries/Admin/CreateEvent/VenueAddOns';
import { unauthenticatedHelper } from 'Helpers/unauthenticatedHelper';
import routeCodes from '../../../../constants/routes';

export const AddOns = () => {
  const {
    user: { venues = [] }
  } = useContext(UserContext);
  const [firstVenue = {}] = venues;
  const { id: venueId } = firstVenue;
  const [loadAddOns, { data = {}, called, loading, error }] = useLazyQuery(VENUE_ADD_ONS, {
    fetchPolicy: 'network-only'
  });
  const addOns = getValueByPropPath(data, 'venue.addOns', []);
  const requiredAddOnsEnabled = getValueByPropPath(data, 'venue.venueConfig.requiredAddons', false);

  useEffect(() => {
    if (venueId) {
      loadAddOns({
        variables: { id: venueId }
      });
    }
  }, [venueId]);

  if (unauthenticatedHelper(error)) return <Redirect to={routeCodes.LOGIN} />;

  return (
    <EventCard title={'Add Ons'} testId="event-create-addons">
      {addOns && addOns.length > 0 ? <AddOnRows addOns={addOns} /> : called && loading ? 'Loading...' : 'This venue does not have add ons'}
      {requiredAddOnsEnabled && <RequiredAddOns />}
    </EventCard>
  );
};
