import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Delete } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Zoom from '@material-ui/core/Zoom';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { Alert, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import colors from '../../../../styles/Colors';
import { EventCard } from '../Cards/EventCard';
import StallSelection from './StallSelection';
import { FormikField, FormikMoneyField } from '../../../../components/Fields';
import CheckboxThemed from '../Checkbox';
import RadioThemed from '../Radio';
import { cleanMoneyInput } from 'Components/Fields/FormikMoneyField';
import { getValueByPropPath } from 'Utils/objectHelpers';
import { emptyStallCard, emptyRvCard } from '../Form';
import { HeadingFour } from 'Components/Headings';
import { momentRange, mapDates } from 'Helpers/momentRange';
import { DATE_FORMAT } from 'Helpers/DATE_FORMAT';
import MinNightsInput from '../Cards/MinNightsInput';
import Flipping from './Flipping';
import { BUILDINGS_WITH_FLIPPING_QUERY } from 'Queries/Admin/CreateEvent/GetBuildingsWithFlipping';
import { LOTS_WITH_FLIPPING_QUERY } from 'Queries/Admin/CreateEvent/GetLotsWithFlipping';
import { getStallsHash } from './stallsHelper';
import { useNoSelectableBuildings } from './useNoSelectableBuildings';
import Calendar from 'Components/Calendar/Calendar';
import { UserContext } from 'Store/UserContext';

const RateBase = ({ className, index = 0, productType, stallsPerBuilding, renderLotNames, renderReservables, scope }) => {
  const { setFieldValue, values } = useFormikContext();
  const { user } = useContext(UserContext);
  const venueTimeZone = user?.venues && user.venues[0].timeZone;
  const [stallsHash, setStallsHash] = useState({});
  const [selectedBuildingsOverlapping, noSelectableBuildingsId] = useNoSelectableBuildings(values.stalls, stallsHash, index);
  const { eventDates, hasStalls, hasRvs, stalls, rvs, checkInTime, checkOutTime } = values;
  const singleProduct = values[productType][index];
  const unit = productType === 'stalls' ? 'stall' : 'spot';
  const unitPlural = productType === 'stalls' ? 'stalls' : 'spots';
  const integrityData = productType === 'stalls' ? 'integrityStallDataErrors' : 'integrityRVDataErrors';
  const entireEvent = getValueByPropPath(values, `${productType}[${index}].entireEvent`, false);
  const isDisabled = productType === 'stalls' ? !hasStalls : !hasRvs;
  const product = productType === 'stalls' ? stalls : rvs;
  const isStallProduct = productType === 'stalls';

  const productStartDate = singleProduct.dateRange.startDate;
  const productEndDate = singleProduct.dateRange.endDate;
  const [getBuildingsWithFlipping, { data }] = useLazyQuery(BUILDINGS_WITH_FLIPPING_QUERY, {
    fetchPolicy: 'network-only'
  });
  const [getLotsWithFlipping, { data: lotsWithFlippingQuery }] = useLazyQuery(LOTS_WITH_FLIPPING_QUERY, {
    fetchPolicy: 'network-only'
  });

  const [selectedStartDate, setSelectedStartDate] = useState(entireEvent ? eventDates.startDate : values[productType][index].dateRange?.startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(entireEvent ? eventDates.endDate : values[productType][index].dateRange?.endDate);

  const removeRate = index => {
    setFieldValue(productType, [...values[productType].slice(0, index), ...values[productType].slice(index + 1)]);
  };

  const emptyCard = productType === 'stalls' ? emptyStallCard : emptyRvCard;

  const newRate = event => {
    event.preventDefault();
    setFieldValue(productType, [...product, JSON.parse(JSON.stringify(emptyCard))]);
  };

  function getNameOrLotHeader() {
    if (productType === 'stalls') {
      return (
        <>
          <HeadingFour className="section-label" label="Name" />
          <Field
            className="rate-name"
            component={FormikField}
            label="RATE NAME"
            name={`${productType}[${index}].name`}
            type="text"
            variant="filled"
            disabled={isDisabled}
          />
        </>
      );
    }
    return renderLotNames(singleProduct.booked, lotsWithFlippingQuery?.lotsWithFlipping || []);
  }

  const pricingChangeHandler = event => {
    const { value } = event.target;
    setFieldValue(`${productType}[${index}].pricing`, value);
  };

  const getPriceLabel = () => {
    if (!values[productType][index].pricing) return 'PRICE';
    if (values[productType][index].pricing === 'flat') {
      return `FLAT PRICE PER ${productType === 'stalls' ? 'STALL' : 'RV'}`;
    }
    return `NIGHTLY PRICE PER ${productType === 'stalls' ? 'STALL' : 'RV'}`;
  };

  const getAllowedDateRange = () => {
    if (!singleProduct.booked) {
      return {};
    }

    const startDateRange = getStartDateRange();
    const endDateRange = getEndDateRange();

    return { allowedRangeForStart: startDateRange, allowedRangeForEnd: endDateRange };
  };

  const getStartDateRange = () => {
    const today = moment();
    const startFrom = moment.max([moment(eventDates.startDate), today]).format(DATE_FORMAT);
    const startTo = singleProduct.startDate;
    const startRangeIsValid = moment(startFrom).isBefore(moment(startTo));

    const fromRange = startRangeIsValid ? momentRange(startFrom, startTo) : [moment(singleProduct.startDate)];
    return mapDates(fromRange);
  };

  const getEndDateRange = () => {
    const endFrom = singleProduct.endDate;
    const endTo = eventDates.endDate;
    const toRange = momentRange(endFrom, endTo);
    return mapDates(toRange);
  };

  const entireEventIsSelectable = () => {
    if (!singleProduct.booked) {
      return true;
    }

    const range = getAllowedDateRange();
    const startIsAvailable = range.allowedRangeForStart.includes(eventDates.startDate);
    const endIsAvailable = range.allowedRangeForEnd.includes(eventDates.endDate);
    return startIsAvailable && endIsAvailable;
  };

  useEffect(() => {
    if (isStallProduct && productStartDate && productEndDate) {
      getBuildingsWithFlipping({
        variables: {
          input: {
            startDate: productStartDate,
            endDate: productEndDate
          }
        }
      });
    }
  }, [values[productType][index].dateRange]);

  useEffect(() => {
    if (!isStallProduct && productStartDate && productEndDate) {
      getLotsWithFlipping({
        variables: {
          input: {
            startDate: productStartDate,
            endDate: productEndDate,
            checkInTime,
            checkOutTime,
            timeZone: venueTimeZone
          }
        }
      });
    }
  }, [values[productType][index].dateRange]);

  useEffect(() => {
    const hash = getStallsHash(stallsPerBuilding);
    setStallsHash(hash);
  }, [stallsPerBuilding]);

  useEffect(() => {
    setFieldValue(`${productType}[${index}].dateRange`, {
      startDate: selectedStartDate,
      endDate: selectedEndDate
    });
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (entireEvent) {
      setFieldValue(`${productType}[${index}].dateRange`, {
        startDate: eventDates.startDate,
        endDate: eventDates.endDate
      });
    }
  }, [entireEvent]);

  const productId = values[productType][index]?.id;
  const stats = values[productType][index]?.stats;
  const hasIntegrityDataErrors = values[integrityData] ? values[integrityData][productId] : false;
  const datesTextTooltip = !stats?.flip
    ? ''
    : stats.dailyStats
        .map(day => {
          const formattedDate = moment(day.date).format('MM/DD');
          return `${formattedDate}: ${day.spotsOccupied} used`;
        })
        .join(' <> ');

  return (
    <EventCard disabled={isDisabled} useStallStyles italicizeHeading={!singleProduct?.name}>
      <div className={className + ' stalls'}>
        <div className={'card-row'} style={{ width: '100%' }}>
          <div className={'card-col'}>
            <HeadingFour className="section-label" label="Available Date Range" />
            <div style={{ display: 'flex', alignItems: 'end' }} className="entire-event-container">
              <Calendar
                selectableRangeStart={eventDates.startDate}
                selectableRangeEnd={eventDates.endDate}
                startDatePlaceholder={'START DATE'}
                endDatePlaceholder={'END DATE'}
                plural={true}
                disabled={isDisabled || entireEvent}
                defaultValue={[
                  entireEvent ? eventDates.startDate : values[productType][index].dateRange?.startDate,
                  entireEvent ? eventDates.endDate : values[productType][index].dateRange?.endDate
                ]}
                cb={({ startDate, endDate }) => {
                  setSelectedStartDate(startDate ? moment(startDate).format(DATE_FORMAT) : null);
                  setSelectedEndDate(endDate ? moment(endDate).format(DATE_FORMAT) : null);
                }}
                styles={{
                  colorBgContainer: '#f1f4f7',
                  colorBorder: 'transparent'
                }}
              />
              <div id="entire-event-container">
                <Field name={`${productType}[${index}].entireEvent`} type="checkbox">
                  {({ field }) => (
                    <FormControlLabel
                      aria-label="Entire event"
                      control={
                        <CheckboxThemed
                          name={field.name}
                          onChange={e => {
                            setFieldValue(field.name, e.target.checked);
                          }}
                          style={{ marginLeft: 15 }}
                          disabled={isDisabled || !entireEventIsSelectable()}
                          checked={entireEvent}
                        />
                      }
                      label="Entire event"
                    />
                  )}
                </Field>
              </div>
            </div>
            {getNameOrLotHeader()}
            <HeadingFour className="section-label" label="Min nights" />
            <MinNightsInput fieldName={`${productType}[${index}].minNights`} product={product} setFieldValue={setFieldValue} values={values} scope={scope} />
            <HeadingFour className="section-label" label="Pricing" />
            <div className={'pricing-fields'}>
              <div>
                <FormControl className="pricing-radio" component="fieldset">
                  <RadioGroup name="pricing" row value={singleProduct?.pricing} onChange={pricingChangeHandler}>
                    <FormControlLabel
                      value="flat"
                      control={<RadioThemed />}
                      label={`Flat rate per ${unit}`}
                      disabled={isDisabled || values[productType][index].booked}
                    />
                    <FormControlLabel
                      value="nightly"
                      control={<RadioThemed />}
                      label={`Nightly rate per ${unit}`}
                      disabled={isDisabled || values[productType][index].booked}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <Field className="money-input" label={getPriceLabel()} name={`${productType}[${index}].price`} type="text" variant="filled">
                  {({ field, meta }) => (
                    <FormikMoneyField
                      {...field}
                      {...meta}
                      error={meta && meta.touched && meta.error ? 'Invalid price' : undefined}
                      helperText={meta && meta.touched && meta.error ? 'Invalid price' : undefined}
                      label={getPriceLabel()}
                      autoComplete={`${productType}[${index}].price`}
                      variant="filled"
                      style={{ marginTop: 10 }}
                      disabled={isDisabled || !singleProduct?.pricing || singleProduct.booked}
                      onChange={e => {
                        setFieldValue(`${productType}[${index}].price`, cleanMoneyInput(e.target.value));
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
            <Flipping
              productType={productType}
              index={index}
              isDisabled={isDisabled}
              stallsPerBuilding={stallsPerBuilding}
              buildingsWithFlipping={data?.buildingsWithFlipping || []}
              stallsHash={stallsHash}
              selectedBuildingsOverlapping={selectedBuildingsOverlapping}
              lotsWithFlipping={lotsWithFlippingQuery?.lotsWithFlipping || []}
            />
          </div>
          <div className={'card-col'} style={{ paddingRight: 0 }}>
            {stats && stats.sold > 0 && (
              <div className="product-stats">
                <div className="stat-wrapper">
                  {hasIntegrityDataErrors && (
                    <div className="warning">
                      <Alert
                        message={`Cannot remove ${unit}. You would create an overbooking situation. This product needs at least ${
                          stats.flip ? stats.maxDailyOccupancy : stats.sold
                        } ${unitPlural}.`}
                        type="error"
                      />
                    </div>
                  )}
                  <div className="stats">
                    <span>
                      <strong>SOLD:</strong> {stats.sold} {stats.flip ? `(max ${stats.maxDailyOccupancy} per day)` : ''}
                    </span>
                    <span>
                      <strong>ASSIGNED:</strong> {stats.assigned}
                    </span>
                    <span>
                      {stats.flip && (
                        <Tooltip placement="topRight" title={datesTextTooltip}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {stallsPerBuilding ? (
              <StallSelection
                stallsPerBuilding={stallsPerBuilding}
                index={index}
                buildingsWithFlipping={data?.buildingsWithFlipping || []}
                selectedBuildingsOverlapping={selectedBuildingsOverlapping}
                noSelectableBuildingsId={noSelectableBuildingsId}
              />
            ) : (
              ''
            )}
            {productType === 'rvs' && renderReservables()}
          </div>
        </div>
        <div className="add-remove-buttons-container">
          <IconButton data-testid="add-rate-button" onClick={newRate}>
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton
            data-testid="remove-rate-button"
            onClick={() => {
              if (product.length === 1) setFieldValue(productType === 'stalls' ? 'hasStalls' : 'hasRvs', false);
              removeRate(index);
            }}
            disabled={isDisabled || singleProduct.booked}>
            <Delete />
          </IconButton>
        </div>
      </div>
    </EventCard>
  );
};

const RateBaseStyled = styled(RateBase)`
  .product-stats {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    justify-content: end;
    flex-flow: row nowrap;
    .stat-wrapper {
      width: 95%;
    }
    .warning {
      margin-bottom: 10px;
    }
    .stats {
      display: flex;
      background-color: #eee;
      padding: 20px;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      font-size: 18px;

      span {
        margin-right: 20px;
        :last-child {
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }
  }
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    .card-col {
      h4:first-child {
        margin-bottom: 10px;
      }
    }
    .pricing-fields {
      margin-top: -5px;
      .pricing-radio {
        margin-bottom: -10px;
      }
    }
    .section-label {
      margin: 20px 0 10px;
    }
    .rate-name {
      margin-bottom: 0 !important;
    }
    .add-remove-buttons-container {
      display: flex;
      justify-content: flex-end;
      opacity: 1;
      margin: 0 -15px -15px 0;
      border-top: 1px solid ${colors.border.primary};
      .MuiSvgIcon-root {
        opacity: 1 !important;
      }
    }

    .entire-event-container .calendar-picker {
      height: 56px;
    }

    #entire-event-container {
      margin-bottom: -10px;
    }
  }
`;

const Rate = ({ index, stallsPerBuilding, productType, renderLotNames, renderReservables, scope }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 1);
  }, []);

  return index === 0 ? (
    <RateBaseStyled
      index={index}
      stallsPerBuilding={stallsPerBuilding}
      productType={productType}
      renderLotNames={renderLotNames}
      renderReservables={renderReservables}
      scope={scope}
    />
  ) : (
    <Zoom in={show}>
      <div style={{ display: 'flex', margin: 0, padding: 0, marginBottom: -45 }}>
        <RateBaseStyled
          index={index}
          stallsPerBuilding={stallsPerBuilding}
          productType={productType}
          renderLotNames={renderLotNames}
          renderReservables={renderReservables}
          scope={scope}
        />
      </div>
    </Zoom>
  );
};

export default Rate;
