import React from 'react';
import { useFormikContext } from 'formik';

import Button from '../../../../components/Button';

const checkDataErrors = errorsObject => {
  if (!errorsObject) return false;

  const keys = Object.keys(errorsObject);
  if (keys.length === 0) return false;

  return keys.some(key => errorsObject[key] === true);
};

export const BottomNavigation = ({ next, back, nextLabel, backLabel, isValid, showSubmit, editView, rvSectionIsEdited, basicDetailsIsEdited }) => {
  const {
    setFieldValue,
    values: { isLoading, hasEmptyRvAnswer, hasEmptyStallAnswer, integrityRVDataErrors, integrityStallDataErrors }
  } = useFormikContext();
  const setStep = step => {
    window.scrollTo(0, 0);
    setFieldValue('step', step);
  };

  const { isSubmitting, isValid: isFormValid } = useFormikContext();
  const hasStallDataErrors = checkDataErrors(integrityStallDataErrors);
  const hasRVDataErrors = checkDataErrors(integrityRVDataErrors);

  const reviewIsDisabled = () => {
    return (
      isLoading ||
      isSubmitting ||
      !isFormValid ||
      hasEmptyRvAnswer ||
      hasEmptyStallAnswer ||
      hasStallDataErrors ||
      hasRVDataErrors ||
      (editView && !basicDetailsIsEdited() && !rvSectionIsEdited)
    );
  };

  const disabled = reviewIsDisabled();

  return (
    <div className={`bottom-navigation ${!back && !!next ? 'solo' : ''}`}>
      {!!back && (
        <a tabIndex={0} role={'button'} onKeyPress={() => setStep(back)} onClick={() => setStep(back)}>
          {backLabel}
        </a>
      )}

      {!!next && (
        <Button type={'button'} className={'next'} tertiary onClick={() => setStep(next)} disabled={!isValid}>
          {nextLabel}
        </Button>
      )}
      {!!showSubmit && (
        <Button
          className="event-build-submit-button"
          type={'submit'}
          onClick={e => {
            e.preventDefault();
            window.scrollTo(0, 0);
            setFieldValue('step', 'review');
          }}
          primary
          isLoading={isLoading || isSubmitting}
          disabled={disabled}>
          REVIEW & SAVE
        </Button>
      )}
    </div>
  );
};
