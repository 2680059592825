import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { Field, useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import WarningModal from 'Components/WarningModal/WarningModal';
import { RESERVATION_STATUSES } from 'Queries/Admin/ReservationsStatuses';
import { TextField } from '../../../../components/Fields';
import { HeadingFive, HeadingFour } from '../../../../components/Headings';
import { FormSelect } from '../../../../components/Select';
import { Separator } from 'Components/Separator';
import { formatOptionName, isEmpty } from '../../../../helpers';
import RvProductSelect from '../../shared/RvProductSelect';
import RvSelection from './RVSelection';
import StallProductSelect from '../../shared/StallProductSelect';
import StallSelection from './StallSelection';
import colors from '../../../../styles/Colors';
import { paragraphReg } from '../../../../styles/Typography';
import { isMaximumAllowedExceeded } from 'Helpers/productLimits';
import { buildOrderItems } from 'Helpers/buildOrderItems';
import UpdatedChip from './UpdatedChip';
import { sortArrayOfObj } from 'Utils/arrayHelpers';
import withUpdateReservationStatus from '../../../../mutations/UpdateEditReservationStatus';
import IncrementerIcon from 'Components/NumberIncrementer/IncrementerIcon';
import EditQuestionAnswers from '../../shared/EditQuestionAnswers';
import { Product } from 'Constants/productType';
import { checkMinNights } from '../../shared/minNights';
import useLengthValidation from '../../shared/useLengthValidation';
import { useValidateAction } from 'Utils/actions';
import { actions } from 'Constants/actions';
import Calendar from 'Components/Calendar/Calendar';
import { ReloadOutlined } from '@ant-design/icons';

const ReservationProductBase = props => {
  const {
    className,
    eventSelected,
    isOpen,
    order,
    questionAnswers,
    productType,
    reservationEdit,
    isWarningOpen,
    setIsWarningOpen,
    handleWarningClose,
    toggleDeleteModalVisibility,
    updateReservationStatus,
    isBelowMinNights,
    setIsBelowMinNights,
    setProductQuestionsAreValid,
    setInvalidProduct
  } = props;
  const { setFieldValue, setFieldError, values, errors } = useFormikContext();
  const [isProductUnavailable, setIsProductUnavailable] = useState(false);
  const [areAvailableProducts, setAreAvailableProducts] = useState(false);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [unavailableProducts, setUnavailableProducts] = useState([]);
  const [currentSelectionAvailableCount, setCurrentSelectionAvailableCount] = useState(0);
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [initialProductId] = useState(productType === 'stallProduct' ? values.stallProductId : values.rvProductId);
  const updateStatus = useLengthValidation(questionAnswers, setProductQuestionsAreValid);

  const productSelectionRef = useRef(null);
  const [isProductDataLoading, setIsProductDataLoading] = useState(false);
  const [areDatesInvalid, setAreDatesInvalid] = useState(null);

  let productName = '';
  switch (productType) {
    case 'stallProduct':
      productName = 'stalls';
      break;
    case 'rvProduct':
      productName = 'rv_spot';
      break;
  }

  const [resStatus, setResStatus] = useState(values[productName].status || 1);

  const { event, selectedRvs, selectedStalls, initialRvProduct, initialStallProduct, selectedStallMinNights, selectedRVMinNights } = values;
  const mapsEnabled = event?.venue?.interactiveMaps;
  const maps = event?.venue?.maps;
  const minNights = productType === 'stallProduct' ? selectedStallMinNights : selectedRVMinNights;
  const { orderItems } = order;
  const reservationOrderItemArray = orderItems.filter(orderItem => {
    return orderItem.reservation && orderItem.reservation[productType];
  });
  const reservationOrderItem = reservationOrderItemArray[0];
  const { reservation } = reservationOrderItem ? reservationOrderItem : {};
  const endDate = reservation?.endDate || event?.endDate;
  const startDate = reservation?.startDate || event?.startDate;
  const { data: reservationStatusesData } = useQuery(RESERVATION_STATUSES, {
    fetchPolicy: 'network-only'
  });
  let reservationStatuses = [];
  if (reservationStatusesData) {
    reservationStatuses = reservationStatusesData.reservationStatuses.reduce((acc, status) => {
      if (Number(status.id) !== 4) {
        acc.push({ value: status.id, label: formatOptionName(status.name) });
      }
      return acc;
    }, []);
  }

  const initialProduct = productType === 'stallProduct' ? initialStallProduct : initialRvProduct;
  const currentProductId = productType === 'stallProduct' ? values.stallProductId : values.rvProductId;
  const updatedEndDate = values[productName].end ? moment(values[productName].end).format('YYYY-MM-DD') : null;
  const initialEndDate = moment(endDate).format('YYYY-MM-DD');
  const updatedStartDate = values[productName].start ? moment(values[productName].start).format('YYYY-MM-DD') : null;
  const initialStartDate = moment(startDate).format('YYYY-MM-DD');
  const hasRateTypeDifferences = initialProduct?.reservation[productType]?.id !== currentProductId && currentProductId && initialProduct !== 'undefined';
  const hasDatesDifference = updatedEndDate && updatedStartDate ? updatedEndDate !== initialEndDate || updatedStartDate !== initialStartDate : false;
  const orderItemsLength = buildOrderItems(values).length;
  const itemQuantity = Number(values[productName]?.quantity) > -1 ? values[productName]?.quantity : reservationOrderItem?.quantity;
  const hasQuantityDiff = initialProduct && itemQuantity > 0 ? itemQuantity !== initialProduct?.quantity : itemQuantity > 0;
  const deletedItem = !itemQuantity && initialProduct?.quantity > 0;
  const initialStallsOrSpotsString = sortArrayOfObj(productType === 'stallProduct' ? selectedStalls : selectedRvs, 'id').reduce(
    (acc, curr) => (acc += curr.id),
    ''
  );
  const currentStallsOrSpotsString = sortArrayOfObj(initialProduct?.reservation[productType === 'stallProduct' ? 'stalls' : 'rvSpots'], 'id').reduce(
    (acc, curr) => (acc += curr.id),
    ''
  );
  const hasStallsOrSpotsDiff = initialStallsOrSpotsString !== currentStallsOrSpotsString;

  const checkProductRateDifferences = type => {
    const productType = type === Product.STALL ? 'stallProduct' : 'rvProduct';
    const initialProduct = type === Product.STALL ? initialStallProduct?.reservation[productType] : initialRvProduct?.reservation[productType];
    const currentProductId = type === Product.STALL ? values.stallProductId : values.rvProductId;
    const currentSelectedProduct = values.event[type === Product.STALL ? 'stallProducts' : 'rvProducts'].find(prod => prod.id === currentProductId);

    const hasNameOrRateDiff =
      type === Product.STALL ? currentSelectedProduct?.nightly !== initialProduct?.nightly : initialProduct?.name !== currentSelectedProduct?.rvLot.name;

    return [hasNameOrRateDiff, currentSelectedProduct];
  };

  const [, currentRvProduct] = checkProductRateDifferences(Product.RV);
  const [, currentStallProduct] = checkProductRateDifferences(Product.STALL);

  const getDatesColumn = () => {
    if (!itemQuantity) {
      return (
        <>
          <div className="header-with-updated">
            <p>Dates</p>
            {deletedItem && <UpdatedChip />}
          </div>
          <p>-</p>
        </>
      );
    }
    return (
      <>
        <div className="header-with-updated">
          <p>Dates</p>
          {(deletedItem || hasDatesDifference) && <UpdatedChip />}
        </div>
        <p>
          {moment(values[productName]?.start || startDate).format('MM/DD/YY')} - {moment(values[productName]?.end || endDate).format('MM/DD/YY')}
        </p>
      </>
    );
  };

  const getTypeColumn = () => {
    if (productType === 'stallProduct') {
      const stallProduct = reservation ? reservation.stallProduct : currentProductId ? order.event.stallProducts.find(el => el.id === currentProductId) : null;
      return (
        <>
          <div className="header-with-updated">
            <p>Rate Type</p>
            {(deletedItem || hasRateTypeDifferences) && <UpdatedChip />}
          </div>
          <p>{!stallProduct || !itemQuantity ? '-' : currentStallProduct.nightly ? 'Nightly' : 'Flat Rate'}</p>
        </>
      );
    }
    if (productType === 'rvProduct') {
      return (
        <>
          <div className="header-with-updated">
            <p>Spot Type</p>
            {(deletedItem || hasRateTypeDifferences) && <UpdatedChip />}
          </div>
          <p>{!itemQuantity ? '-' : currentRvProduct?.name}</p>
        </>
      );
    }
    return null;
  };

  const getSpacesColumn = () => {
    if (productType === 'stallProduct') {
      return (
        <>
          <div className="header-with-updated">
            <p>Stalls</p>
            {(deletedItem || hasStallsOrSpotsDiff) && <UpdatedChip />}
          </div>
          {(!selectedStalls.length && !reservation) || !itemQuantity ? (
            '-'
          ) : (
            <p>
              {!selectedStalls.length
                ? `${itemQuantity} stall${itemQuantity === 1 ? '' : 's'}`
                : `${selectedStalls
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
                    .map(stall => stall.name)
                    .join(', ')}`}
            </p>
          )}
        </>
      );
    }
    if (productType === 'rvProduct') {
      return (
        <>
          <div className="header-with-updated">
            <p>Spots</p>
            {(deletedItem || hasStallsOrSpotsDiff) && <UpdatedChip />}
          </div>
          {(!selectedRvs.length && !reservation) || !itemQuantity ? (
            '-'
          ) : (
            <p>
              {!selectedRvs.length
                ? `${itemQuantity} spot${itemQuantity === 1 ? '' : 's'}`
                : `
            ${selectedRvs
              .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
              .map(spot => spot.name)
              .join(', ')}`}
            </p>
          )}
        </>
      );
    }
    return null;
  };

  const setDateRange = dates => {
    setFieldValue(productName, {
      ...values[productName],
      start: dates.startDate,
      end: dates.endDate
    });
  };

  const getStatusName = statuses => {
    if (isEmpty(values[productName])) return '';
    return statuses.find(option => option.value === values[productName].status) || null;
  };

  const showQuestions = qa => {
    return (
      <>
        <p className="question">{qa.question}</p>
        <p>{qa.answer.join(', ')}</p>
      </>
    );
  };

  let orderItemId;
  const getOrderItemStatus = (items, productType, statusId, orderId) => {
    if (items.some(i => i[reservation])) {
      orderItemId = items.find(el => el.reservation && el.reservation[productType] && el.id).id;
      updateReservationStatus({ orderItemId, statusId }, { id: orderId });
    }
  };

  const statusName = getStatusName(reservationStatuses);
  const statusOptions = {
    cb: e => {
      getOrderItemStatus(Object.values(orderItems), productType, e.target.value, order.id);
      setResStatus(e.target.value);
      setFieldValue(`${productName}.status`, e.target.value);
    },
    key: statusName ? statusName.value : null,
    label: 'STATUS',
    options: reservationStatuses,
    selectedOption: resStatus, //values[productName] ? values[productName].status : '',
    type: 'select',
    value: resStatus
  };

  const getProductSelectionHeader = () => {
    let headerText = '';
    switch (productName) {
      case 'rv_spot':
        headerText = 'RV Spot Type';
        break;
      case 'stalls':
        headerText = 'Stall Rate Type';
        break;
    }
    return headerText;
  };

  const quantityChange = async newValue => {
    const value = newValue;
    if (value === '0') {
      setIsWarningOpen();
    } else {
      setFieldValue(`${productName}.quantity`, Number(value) < 0 ? '' : Number(value));
      if (isMaximumAllowedExceeded(value, productType)) {
        setFieldError(`${productName}.quantity`, 'Maximum exceeded');
      }
    }
  };

  const handleUnavailableProducts = products => {
    setUnavailableProducts(prev => {
      if (prev.length !== products.length) {
        return [...products];
      }
      return prev;
    });
  };

  const handleReset = () => {
    if (hasDatesDifference) {
      setFieldValue(`${productName}.start`, moment(initialStartDate).format('YYYY-MM-DD'));
      setFieldValue(`${productName}.end`, moment(initialEndDate).format('YYYY-MM-DD'));
    }

    if (hasQuantityDiff) {
      setFieldValue(`${productName}.quantity`, initialProduct?.quantity);
    }

    setIsProductUnavailable(false);
    setAreAvailableProducts(false);

    if (productName === 'stalls') {
      setFieldValue('stallProductId', initialProductId);
    } else {
      setFieldValue('rvProductId', initialProductId);
    }
  };

  const handleClearSelections = () => {
    if (productName === 'stalls') {
      setFieldValue('selectedStalls', []);
      setFieldValue('stallProductId', null);
    } else {
      setFieldValue('selectedRvs', []);
      setFieldValue('rvProductId', null);
    }

    setAreAvailableProducts(false);
    setIsProductUnavailable(false);
  };

  const handleProductAvailability = productAvailability => {
    setAvailableProducts(productAvailability);
  };

  const handleAvailabilityLoading = loading => {
    setAvailabilityLoading(loading);
  };

  useEffect(() => {
    const currentProductId = productType === 'stallProduct' ? values.stallProductId : values.rvProductId;
    const currentSelectedProduct = values.event[productType === 'stallProduct' ? 'stallProducts' : 'rvProducts'].find(prod => prod.id === currentProductId);
    if (currentSelectedProduct && updatedStartDate) {
      const rateStartDate = currentSelectedProduct.startDate;
      const isInvalidDate = moment(updatedStartDate).isBefore(moment(rateStartDate));
      setAreDatesInvalid(isInvalidDate ? rateStartDate : null);
    }
  }, [productType, values.stallProductId, values.rvProductId, updatedStartDate]);

  useEffect(() => {
    const currentProductAvailability = availableProducts.find(x => x.productId === currentProductId)?.available;
    const otherProductsAvailability = availableProducts
      .filter(x => x.productId !== currentProductId)
      .map(x => x.available)
      .reduce((state, item) => state + item, 0);

    setAreAvailableProducts(false);
    setIsProductUnavailable(false);
    setCurrentSelectionAvailableCount(currentProductAvailability);

    if ((hasRateTypeDifferences || hasDatesDifference || hasQuantityDiff) && !availabilityLoading) {
      if ((!currentProductAvailability || currentProductAvailability === 0) && (!otherProductsAvailability || otherProductsAvailability === 0) && isOpen) {
        setIsProductUnavailable(true);
        setAreAvailableProducts(false);
        return;
      }

      if (
        (!currentProductAvailability || (currentProductAvailability === 1 && currentProductAvailability > itemQuantity)) &&
        otherProductsAvailability &&
        otherProductsAvailability > 0 &&
        !!currentProductId &&
        hasQuantityDiff
      ) {
        setIsProductUnavailable(false);
        setAreAvailableProducts(true);
        return;
      }

      if (
        (!currentProductAvailability || currentProductAvailability === 0 || currentProductAvailability < itemQuantity) &&
        otherProductsAvailability &&
        otherProductsAvailability > 0 &&
        !!currentProductId
      ) {
        setIsProductUnavailable(true);
        setAreAvailableProducts(true);
        return;
      }

      if (
        currentProductAvailability &&
        currentProductAvailability > 0 &&
        currentProductAvailability === itemQuantity &&
        (!otherProductsAvailability || otherProductsAvailability === 0)
      ) {
        setIsProductUnavailable(false);
        setAreAvailableProducts(false);
        return;
      }

      if (
        currentProductAvailability &&
        currentProductAvailability > 0 &&
        currentProductAvailability === itemQuantity &&
        (!otherProductsAvailability || otherProductsAvailability > 0)
      ) {
        setIsProductUnavailable(false);
        setAreAvailableProducts(true);
        return;
      }

      if (
        currentProductAvailability &&
        currentProductAvailability > 0 &&
        currentProductAvailability < itemQuantity &&
        (!otherProductsAvailability || otherProductsAvailability === 0)
      ) {
        setIsProductUnavailable(true);
        setAreAvailableProducts(false);
        return;
      }

      if (currentProductAvailability && currentProductAvailability > 0) {
        setIsProductUnavailable(false);
        setAreAvailableProducts(false);
        setFieldError(productName, '');
        return;
      }
    }
  }, [availableProducts, hasRateTypeDifferences, hasDatesDifference, hasQuantityDiff, availabilityLoading]);

  useEffect(() => {
    if (unavailableProducts?.length > 0 && setInvalidProduct) {
      setInvalidProduct(true);
    } else if (unavailableProducts?.length === 0 && setInvalidProduct) {
      setInvalidProduct(false);
    }
  }, [unavailableProducts]);

  const WARNING_MESSAGE =
    hasDatesDifference && !hasQuantityDiff
      ? 'This product is not available for the dates selected.'
      : `Only ${currentSelectionAvailableCount} left of the product selected.`;

  const startDatePast = moment(event.startDate).isAfter(values[productName].start);
  const generalError = errors[`${productName}`] && !!errors[`${productName}`].quantity ? true : !values[productName];

  const handleRefreshClick = () => {
    if (productSelectionRef.current) {
      if (productType === 'stallProduct') {
        productSelectionRef.current.refetchStalls();
      } else {
        productSelectionRef.current.refetchRVs();
      }
    }
  };

  const canAssignStalls = useValidateAction('orders', actions.PRODUCT_ASSIGNMENT);
  const canChangeStallsStatus = useValidateAction('orders', actions.RV_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR);
  const canAssignRvs = useValidateAction('orders', actions.PRODUCT_ASSIGNMENT);
  const canChangeRvsStatus = useValidateAction('orders', actions.STALL_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR);
  const canAssignReservations = canAssignStalls && canAssignRvs;
  const canChangeStatus = canChangeStallsStatus && canChangeRvsStatus;

  return (
    <ReservationProduct className={`${className ? className : ''} ${isMobile ? 'mobile' : ''}`} isMobile={isMobile}>
      <WarningModal
        isOpen={isWarningOpen}
        onCancel={handleWarningClose}
        handleClose={handleWarningClose}
        continueLabel="DELETE"
        cancelLabel="GO BACK"
        header="ARE YOU SURE?"
        text={`Are you sure you would like to delete your entire ${orderItemsLength < 2 ? '' : productType === 'stallProduct' ? 'stall' : 'rv'} reservation?`}
        onContinue={async () => {
          if (orderItemsLength < 2) {
            setIsWarningOpen();
            toggleDeleteModalVisibility();
          } else {
            await setFieldValue(`${productName === 'stalls' ? 'selectedStalls' : 'selectedRvs'}`, []);
            setFieldValue(`${productName}.quantity`, 0);
            setFieldValue(productName === 'stalls' ? 'stallProductId' : 'rvProductId', null);
            handleWarningClose();
          }
        }}
      />
      <WarningModal
        isOpen={isProductUnavailable && values[productName].quantity >= previousQuantity && values[productName].quantity >= initialProduct?.quantity}
        handleClose={handleReset}
        continueLabel="Ok"
        header="PRODUCT UNAVAILABLE"
        text="This product is not available for the dates selected"
        onContinue={handleReset}
      />
      <WarningModal
        isOpen={
          areAvailableProducts &&
          isProductUnavailable &&
          values[productName].quantity > previousQuantity &&
          values[productName].quantity > initialProduct?.quantity
        }
        handleClose={handleReset}
        continueLabel="Yes"
        cancelLabel="Don't update"
        onCancel={handleReset}
        header="PRODUCT UNAVAILABLE"
        text={`${WARNING_MESSAGE} Would you like to make a new selection? This would clear any assignments.`}
        onContinue={handleClearSelections}
      />
      {!isOpen && reservationEdit ? (
        <>
          <Separator margin="0.625rem 0 1.375rem" />
          <div className="reservation-info">
            <div className="reservation-dates">{getDatesColumn()}</div>
            <div className="reservation-type">{getTypeColumn()}</div>
            <div className="reservation-spaces">{getSpacesColumn()}</div>
          </div>
          <div className="question-answers">{questionAnswers.length > 0 && questionAnswers.map(qa => showQuestions(qa))}</div>
        </>
      ) : null}
      <div className={`card-content ${isOpen ? `open` : ''}`}>
        {isOpen ? (
          <>
            <Separator margin="0.625rem 0 1.375rem" />
            <Grid container className="qty-and-date">
              <Grid item md={6} direction="column" className="quantity-column">
                <div className="heading-five">
                  <HeadingFive label={'Quantity'} className="" />
                  {hasQuantityDiff && <UpdatedChip />}
                </div>
                <Field name={`${productName}.quantity`}>
                  {({ field, meta }) => (
                    <div className="quantity-field-wrapper">
                      <IncrementerIcon
                        increment={() => {
                          let value = Number(field.value) + 1;
                          setPreviousQuantity(Number(field.value));
                          quantityChange(value.toString());
                        }}
                        decrement={() => {
                          let value = Number(field.value) - 1;
                          setPreviousQuantity(Number(field.value));
                          quantityChange(value.toString());
                        }}
                        top={29}
                        right={22}
                      />
                      <TextField
                        {...field}
                        {...meta}
                        className="quantity-field"
                        disabled={!eventSelected && !reservationEdit}
                        error={Boolean(meta.error) || isMaximumAllowedExceeded(meta.value, productType)}
                        helperText={!!meta.error && meta.error}
                        inputProps={{ maxLength: 3 }}
                        label={`NUMBER OF ${productType === 'stallProduct' ? 'STALLS' : 'SPOTS'}`}
                        onBlur={e => {
                          if (isMaximumAllowedExceeded(e.target.value, productType)) {
                            setFieldError(`${productName}.quantity`, 'Maximum exceeded');
                          }
                        }}
                        onChange={e => quantityChange(e.target.value)}
                        type={'text'}
                        value={values[productName].quantity}
                        variant={'filled'}
                      />
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item container md={6} direction="column" className="date-column">
                <div className="date-wrapper">
                  <div className="heading-five">
                    <HeadingFive label={'Dates'} />
                    {hasDatesDifference && <UpdatedChip />}
                  </div>
                  <div className={`date-input--wrapper ${isBelowMinNights ? 'error' : ''}`}>
                    <Calendar
                      selectableRangeStart={moment(event.startDate).format('YYYY-MM-DD')}
                      selectableRangeEnd={moment(event.endDate).format('YYYY-MM-DD')}
                      startDatePlaceholder={'CHECK IN'}
                      endDatePlaceholder={'CHECK OUT'}
                      plural={true}
                      defaultValue={[values[productName].start, values[productName].end]}
                      disabled={generalError || startDatePast}
                      cb={e => {
                        const { startDate: calendarStart, endDate: calendarEnd } = e;
                        if (!calendarStart || !calendarEnd) {
                          setFieldValue(`${productName}.start`, moment(values[productName].start || e.startDate).format('YYYY/MM/DD'));
                          setFieldValue(`${productName}.end`, moment(values[productName].end || e.endDate).format('YYYY/MM/DD'));
                          setDateRange(e);
                          checkMinNights(e, minNights, setIsBelowMinNights);
                        } else {
                          setFieldValue(`${productName}.start`, moment(calendarStart).format('YYYY/MM/DD') || e.startDate.format('YYYY/MM/DD'));
                          setFieldValue(`${productName}.end`, moment(calendarEnd).format('YYYY/MM/DD') || e.endDate.format('YYYY/MM/DD'));
                          setDateRange(e);
                          checkMinNights(e, minNights, setIsBelowMinNights);
                        }
                      }}
                      styles={{
                        colorBgContainer: '#f1f4f7',
                        colorBorder: 'transparent'
                      }}
                    />
                    <span className="error-msg">Min Nights is {minNights}</span>
                    {areDatesInvalid && (
                      <span className="warning-dates-msg">
                        Warning! The selected rate starts on {moment(areDatesInvalid).format('MM/DD')}. Make sure this is intentional
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {canChangeStatus && (
              <>
                <HeadingFive label={`${productType === 'stallProduct' ? 'Stall' : 'RV Spot'} Reservation Status`} className="spacing-top" />
                <FormSelect className="status-options" {...statusOptions} />
              </>
            )}
            <div style={{ display: 'flex' }}>
              <HeadingFive label={getProductSelectionHeader()} className="spacing-bottom" />
              {hasRateTypeDifferences && <UpdatedChip />}
            </div>
            {unavailableProducts.length > 0 && (
              <div className="unavailable-products-message">
                {productType === 'stallProduct' ? 'Stall' : 'Spot'} {unavailableProducts.map(x => x.name).join(', ')} is assigned to another renter during some
                of the selected dates. Select new {productType === 'stallProduct' ? 'stalls' : 'spots'} or unassign the other renter to save this reservation.
              </div>
            )}
            {productType === 'stallProduct' ? (
              <StallProductSelect
                className={className}
                noLayout
                reservationEdit={reservationEdit}
                handleProductAvailability={a => handleProductAvailability(a)}
                loadingHandler={handleAvailabilityLoading}
              />
            ) : (
              <RvProductSelect
                className={className}
                noLayout
                reservationEdit={reservationEdit}
                handleProductAvailability={a => handleProductAvailability(a)}
                loadingHandler={handleAvailabilityLoading}
              />
            )}
            {canAssignReservations && (
              <>
                <div className="refresh-button-container">
                  <HeadingFour className="assignment-heading" label={`Available ${productName === 'rv_spot' ? 'RV Spots' : 'Stalls'}`} />
                  <span title="Refresh" onClick={handleRefreshClick} className="refresh-icon-wrapper">
                    <ReloadOutlined className={isProductDataLoading ? 'spinning' : ''} style={{ fontSize: '20px', color: '#2875c3' }} />
                  </span>
                </div>
                <p className="subheading">Assign {productName === 'rv_spot' ? 'RV spots' : productName} for this reservation</p>
                {productType === 'stallProduct' ? (
                  <StallSelection
                    isOpen={isOpen}
                    setUnavailableProducts={handleUnavailableProducts}
                    hasDatesDifference={hasDatesDifference}
                    hasQuantityDiff={hasQuantityDiff}
                    mapsEnabled={mapsEnabled}
                    maps={maps}
                    ref={productSelectionRef}
                    onLoadingChange={setIsProductDataLoading}
                  />
                ) : (
                  <RvSelection
                    isOpen={isOpen}
                    setUnavailableProducts={handleUnavailableProducts}
                    hasDatesDifference={hasDatesDifference}
                    hasQuantityDiff={hasQuantityDiff}
                    mapsEnabled={mapsEnabled}
                    maps={maps}
                    ref={productSelectionRef}
                    onLoadingChange={setIsProductDataLoading}
                  />
                )}
              </>
            )}
            <Separator margin="1.375rem 0" />
            <div className="heading-container">
              <HeadingFour label={`Additional ${productType === 'stallProduct' ? 'Stall' : 'RV'} Info`} />
            </div>
            {questionAnswers.map((question, index) => (
              <>
                <p className={question.questionType === 'openText' ? 'open-text' : ''}>
                  {question.question}
                  {question.required && <span className="required-text"> (Required)</span>}
                </p>
                <EditQuestionAnswers
                  question={question}
                  updateStatus={updateStatus}
                  index={index}
                  productType={productType === 'stallProduct' ? 'stalls' : 'spots'}
                />
              </>
            ))}
          </>
        ) : (
          ''
        )}
      </div>
    </ReservationProduct>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ReservationProduct = styled.div`
  .reservation-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    > div p:first-child {
      font-family: 'IBMPlexSans-SemiBold';
      font-size: 18px;
      letter-spacing: 0.79px;
      line-height: 23px;
    }

    p {
      margin: 0;
    }
  }

  .reservation-spaces {
    .header-with-updated {
      display: flex;
      flex-diection: row;
    }
  }

  .reservation-type {
    .header-with-updated {
      display: flex;
      flex-diection: row;
    }
  }

  .reservation-dates {
    .header-with-updated {
      display: flex;
      flex-diection: row;
    }
  }

  .heading-five {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .assignment-heading {
    font-size: 18px !important;
  }

  .subheading {
    margin: 0;
  }

  .spacing-top {
    margin-top: 20px !important;
  }

  .spacing-bottom {
    margin-bottom: 20px !important;
  }

  .quantity-field {
    margin-top: 5px;
  }

  .cal-label {
    display: flex;
    span {
      text-transform: uppercase;
      font-family: 'IBMPlexSans-Regular';
      font-size: 12px;
      color: ${colors.text.secondary};
      flex-basis: 50%;
      &:nth-child(2) {
        text-align: right;
        margin-right: 5px;
      }
    }
  }

  .status-options {
    ${paragraphReg}
    color: rgb(0, 0, 0);
    font-size: 0.9375rem;
    line-height: 24px;
    margin: 10px 0 20px;
    padding: 13px 0 0;
    background-color: ${colors.background.primary} !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &::placeholder {
      color: ${colors.text.secondary};
    }
    &--disabled {
      opacity: 0.5;
    }

    label[class^='MuiInputLabel-formControl'],
    label[class*='MuiInputLabel-formControl'] {
      top: -13px !important;
    }
    svg[class^='MuiSelect-icon'],
    svg[class*='MuiSelect-icon'] {
      top: -2px !important;
    }

    label[class^='MuiFormLabel-filled'],
    label[class*='MuiFormLabel-filled'] {
      top: -12px !important;
    }
    div[class^='MuiSelect-selectMenu'],
    div[class*='MuiSelect-selectMenu'] {
      margin-bottom: 15px !important;
    }
  }

  .quantity-field-wrapper {
    position: relative;
    padding-right: 12px;
  }

  .date-input--wrapper {
    height: 56px;
    margin-top: 15px;

    .calendar-picker {
      width: 100%;
      height: 100%;
      margin-top: 5px;
    }

    .error-msg {
      display: none;
      color: #f44336;
    }

    .warning-dates-msg {
      display: block;
      color: #f44336;
      font-weight: 600;
      margin-top: 5px;
    }

    &.error {
      .error-msg {
        display: block;
        font-weight: 600;
      }

      > div {
        border-bottom: 2px solid #f44336;
      }
    }
  }

  .question-answers {
    margin-top: 20px;

    .question {
      font-family: 'IBMPlexSans-SemiBold';
      font-size: 18px;
      letter-spacing: 0.79px;
      line-height: 23px;
    }
  }

  .unavailable-products-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 74px;
    background: #ee5253;
    border-radius: 3px;
    margin: 10px 0px;
    color: #fff;
  }

  &.mobile {
    .reservation-info {
      display: flex;
      flex-direction: column;
    }

    .qty-and-date {
      flex-direction: column;
    }

    .quantity-column {
      padding-right: 0;
      width: 100%;
    }

    .date-column {
      padding-left: 0;
      width: 100%;
    }

    .quantity-field-wrapper {
      padding-right: 0;
    }
  }

  .refresh-button-container {
    display: flex;
    align-items: center;

    .refresh-icon-wrapper {
      cursor: pointer;
      margin-left: 10px;
      line-height: 0;
      display: inline-flex;
    }

    .spinning {
      animation: ${spin} 1s linear infinite;
    }
  }
`;

export default withUpdateReservationStatus(ReservationProductBase);
