import React from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/react-hooks';

import FormCard from '../../../../components/Cards/FormCard';
import Button from '../../../../components/Button';
import { displayFlex } from '../../../../styles/Mixins';
import colors from '../../../../styles/Colors';
import FormikTicket from './FormikTicket';
import useReservationFlowRoutes from './useReservationFlowRoutes';
import { isPaymentInformationValid, isZeroRatePaymentValid } from '../../shared/sharedMethods';
import { isEmpty } from 'Helpers/isEmpty';
import { ORDER_CREATE_COSTS } from 'Queries/OrderCreateCosts';
import { buildOrderItems } from '../../../../helpers';
import { isNumeric } from 'Utils/numberHelpers';
import { GET_MAPS_BY_VENUE_ID } from 'Queries/Maps/getMapsByVenueId';

const ReservationSummary = props => {
  const { className = '', elements, stripe, setIsZeroRatesForm, stallQuestionsAreValid, rvQuestionsAreValid, rvSoldOut } = props;
  const { values, errors, isSubmitting } = useFormikContext();
  const {
    event,
    event: { venueAgreement },
    rvProductId,
    hasEmptyRVQuestions,
    hasEmptyStallQuestions,
    stallProductId
  } = values;

  const { data: mapsData, error: mapsError } = useQuery(GET_MAPS_BY_VENUE_ID, {
    variables: { venueId: event.venue.id },
    fetchPolicy: 'network-only'
  });

  const { isStallsUrl, isCheckoutUrl, isRvsUrl, goToRvs, goToCheckout } = useReservationFlowRoutes();
  const rvErrors = Boolean(errors.rv_spot) || !rvProductId;
  const stallErrors = Boolean(errors.stalls) || !stallProductId;
  const addOnErrors = Boolean(errors.addOns && errors.addOns.length > 0) || Boolean(errors.addOnDeliveryNotes);
  const hasRvProducts = event.rvProducts.length > 0;

  const isAddOnOnlyEvent = event.allowAddOnOnlyOrder && event.addOnProducts?.length > 0;
  const hasSelectedAddOns = Object.values(values.addOns).some(addOnQty => addOnQty > 0);
  const hasSelectedStall = isNumeric(values.stallProductId);
  const hasSelectedRV = isNumeric(values.rvProductId);

  let isValidStallInfoForMaps = true;
  let isValidRVInfoForMaps = true;

  if (event?.venue?.interactiveMaps && event?.venue?.interactiveMapsForRenters && event?.venue?.requiredMapAssignmentsForRenters && !mapsError) {
    const rvMaps = mapsData?.maps?.filter(m => m.type === 'lot');
    const stallMaps = mapsData?.maps?.filter(m => m.type === 'barn');

    if (values?.stalls?.quantity && values?.selectedStalls && values?.stalls?.quantity !== values?.selectedStalls?.length && stallMaps?.length > 0) {
      isValidStallInfoForMaps = false;
    }

    if (values?.rv_spot?.quantity && values?.selectedRvs && values?.rv_spot?.quantity !== values?.selectedRvs?.length && rvMaps?.length > 0) {
      isValidRVInfoForMaps = false;
    }
  }

  const orderItemsArray = buildOrderItems(values);
  const { data: orderCosts, loading: orderCostsLoading } = useQuery(ORDER_CREATE_COSTS, {
    variables: {
      input: {
        selectedOrderItems: orderItemsArray,
        useCard: false,
        isNonUSCard: false
      }
    },
    fetchPolicy: 'network-only'
  });
  const actualOrderCosts = orderCosts && orderCosts.orderCosts;
  const { total, discount } = actualOrderCosts ? actualOrderCosts : {};

  setIsZeroRatesForm(total === 0 && discount > 0);

  let fCtx;
  if (total === 0 && discount > 0) {
    fCtx = useFormikContext();
  } else {
    fCtx = useFormikContext();
  }

  const isSubmitDisabled = () => {
    if (stallPageIsValid()) {
      return false;
    }

    if (rvPageIsValid()) {
      return false;
    }

    if (isCheckoutUrl) {
      if (!values.group && !values.ccInformation?.useCard && !values.ccInformation?.saveCard) {
        return true;
      }

      if (values.group) {
        if (values?.ccInformation?.selectedCard?.length && errors?.ccInformation?.zipCode) {
          delete errors.ccInformation;
        }

        if (!event.isGroupCodeRequired && !isPaymentInformationValid(values, elements)) {
          return true;
        }

        return !isZeroRatePaymentValid(total, discount, errors) && !isEmpty(errors);
      }

      if (values?.ccInformation?.selectedCard?.length && errors?.ccInformation?.zipCode) {
        delete errors.ccInformation;
      }

      return !isZeroRatePaymentValid(total, discount, errors) && (!isPaymentInformationValid(values, elements) || !isEmpty(errors));
    }

    return true;
  };

  const stallPageIsValid = () => {
    if (!isStallsUrl) {
      return false;
    }
    if (!isAddOnOnlyEvent || hasSelectedStall) {
      const stallQuestionsAreValid = Object.keys(hasEmptyStallQuestions)?.length === 0;
      return !stallErrors && !addOnErrors && stallQuestionsAreValid;
    }
    if (isAddOnOnlyEvent && !hasSelectedStall) {
      return hasSelectedAddOns && !addOnErrors;
    }
  };

  const rvPageIsValid = () => {
    if (!isRvsUrl) {
      return false;
    }
    if (!isAddOnOnlyEvent || hasSelectedRV) {
      const rvQuestionsAreValid = Object.keys(hasEmptyRVQuestions)?.length === 0;
      return !rvErrors && !addOnErrors && rvQuestionsAreValid;
    }
    if (isAddOnOnlyEvent && !hasSelectedRV) {
      return hasSelectedAddOns && !addOnErrors;
    }
  };

  const checkoutNextHandler = e => {
    e.preventDefault();
    if (isStallsUrl || isRvsUrl) {
      goToCheckout();
    }
    window.scrollTo(0, 0);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await fCtx.submitForm();
    window.scrollTo(0, 0);
  };

  const handleGoToRvs = () => {
    goToRvs();
    window.scrollTo(0, 0);
  };

  const isEditingStalls = stalls => {
    return !!(stalls.quantity > 0 && isSubmitDisabled());
  };

  const areMinNightsValid = () => {
    return !!(values.isBelowMinNights.stalls || values.isBelowMinNights.rvs);
  };

  const venueAgreementCheckbox = (
    <StyledBlurb>
      All transaction fees are non-refundable. Please be sure you have read the&nbsp;
      <a href={`${venueAgreement ? venueAgreement.url : '#'}`} target="_blank" rel="noopener noreferrer">
        Venue Agreement&nbsp;
      </a>
      before clicking <strong>SUBMIT</strong>.
    </StyledBlurb>
  );

  return (
    <FormCard className={className} dataTestId="renter_reservation_summary">
      <div className={`${className} flex-checkout-container`}>
        <FormikTicket className={className} elements={elements} stripe={stripe} />
        {isCheckoutUrl && venueAgreementCheckbox}

        <div className={'actions'}>
          <Button
            data-testid="reservation-summary-contiune-btn"
            primary
            variant="contained"
            size="large"
            type={isCheckoutUrl ? 'submit' : 'button'}
            disabled={
              isSubmitDisabled() ||
              isSubmitting ||
              !stallQuestionsAreValid ||
              !rvQuestionsAreValid ||
              areMinNightsValid() ||
              !isValidStallInfoForMaps ||
              !isValidRVInfoForMaps
            }
            onClick={isCheckoutUrl ? handleSubmit : checkoutNextHandler}
            isLoading={isSubmitting || orderCostsLoading}>
            {(isStallsUrl || isRvsUrl) && 'CONTINUE TO CHECKOUT'}
            {isCheckoutUrl && 'SUBMIT'}
          </Button>
          {!(isRvsUrl || isCheckoutUrl) && (
            <Button
              data-testid="add-rvs-btn"
              className={hasRvProducts ? '' : 'unavailable-rv-button'}
              secondary
              variant="contained"
              size="large"
              type="button"
              disabled={
                isEditingStalls(values?.stalls) ||
                isSubmitting ||
                !hasRvProducts ||
                rvSoldOut ||
                !stallQuestionsAreValid ||
                !rvQuestionsAreValid ||
                areMinNightsValid() ||
                !isValidStallInfoForMaps ||
                !isValidRVInfoForMaps
              }
              onClick={handleGoToRvs}>
              {!event.rvProducts.length ? 'RV SPOTS UNAVAILABLE' : rvSoldOut ? 'RV SPOTS SOLD OUT' : 'BOOK RV SPOT(S)'}
            </Button>
          )}
        </div>
      </div>
    </FormCard>
  );
};

const ReservationSummaryStyled = styled(ReservationSummary)`
  &.flex-checkout-container {
    ${displayFlex}
    flex-direction: column;
    font-size: 16px;

    .unavailable-rv-button {
      border: none;
      background-color: white;
    }

    .actions {
      margin: 0;

      button {
        height: 36px;
        width: 100%;
        margin: 15px 0;
        &:first-child {
          margin: 25px 0 0;
        }
      }
    }

    .progress-spinner {
      &&& {
        bottom: 3%;
        color: ${colors.primary};
        left: 44%;
        position: absolute;
      }
    }
  }
`;

const StyledBlurb = styled.span`
  font-size: 15px;
  text-align: center;
  border-top: 1px solid #c8d6e5;
  padding-top: 16px;

  strong {
    font-family: 'IBMPlexSans-Bold';
  }
`;

export default ReservationSummaryStyled;
