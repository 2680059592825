/* global API_GATEWAY_TOKEN */
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { withSnackbarContextActions } from 'Store/SnackbarContext';
import React, { memo, useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { HeadingOne } from 'Components/Headings';
import { displayFlex } from '../../../styles/Mixins';
import { useQuery } from '@apollo/react-hooks';
import { Button, Input, message, Space, Table, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Events from 'Pages/SuperAdmin/Venues/Events';
import Calendar from 'Components/Calendar/Calendar';
import Auth from 'Lib/auth';
import download from 'downloadjs';
import { Report } from 'Constants/reportType';
import { UserContext } from 'Store/UserContext';
import { VENUES_COMPLETE } from 'Queries/SuperAdmin/VenuesComplete';

const renderBooleanTag = value => {
  const color = value ? 'green' : 'red';
  const text = value ? 'ON' : 'OFF';
  return <Tag color={color}>{text}</Tag>;
};

function Venues({ className }) {
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useContext(UserContext);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [venuesList, setVenuesList] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [nextBookingOpening, setNextBookingOpening] = useState(null);
  const [nextStartingEvent, setNextStartingEvent] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL || '';

  const { data: venuesData, error: venuesError, loading } = useQuery(VENUES_COMPLETE);

  const searchInput = useRef(null);

  const handleSearch = (_, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      }
    },
    render: text => text
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      fixed: 'left',
      sorter: {
        compare: (a, b) => +a.id - +b.id,
        multiple: 5
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      ...getColumnSearchProps('name'),
      fixed: 'left',
      render: value => (
        <>
          <strong>{value}</strong>
        </>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Location',
      dataIndex: 'street',
      key: 'street',
      render: (_, venue) => {
        const gmapsLink = `https://www.google.com/maps/search/?api=1&query=${venue.street}+${venue.street2}+${venue.city}+${venue.state}+${venue.zip}`;
        return (
          <>
            <a target={'_blank'} rel="noreferrer" href={gmapsLink}>
              {venue.street}
              {venue.street2 ? `, ${venue.street2}` : ''}, {venue.city}, {venue.state}, {venue.zip}
            </a>
          </>
        );
      }
    },
    {
      title: 'Time Zone',
      dataIndex: 'timeZone',
      key: 'timeZone'
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => moment(text).format('MM/DD/YYYY')
    },
    {
      title: 'Stripe Acc',
      dataIndex: 'stripeAccount',
      key: 'stripeAccount',
      ...getColumnSearchProps('stripeAccount'),
      render: value => (
        <>
          <a target={'_blank'} rel="noreferrer" href={`https://dashboard.stripe.com/connect/accounts/${value}`}>
            {value}
          </a>
        </>
      )
    },
    {
      title: 'Stripe Type',
      dataIndex: 'stripeAccountType',
      key: 'stripeAccountType'
    },
    {
      title: 'Platform Fee',
      dataIndex: 'platformFee',
      key: 'platformFee',
      render: value => `$${value}`,
      sorter: {
        compare: (a, b) => a.platformFee - b.platformFee,
        multiple: 5
      }
    },
    {
      title: '% Fee',
      dataIndex: 'percentageFee',
      key: 'percentageFee',
      render: value => `${value}%`,
      sorter: {
        compare: (a, b) => a.percentageFee - b.percentageFee,
        multiple: 1
      }
    },
    {
      title: 'Fee/Product',
      dataIndex: 'feePerProduct',
      key: 'feePerProduct',
      render: value => `$${value}`,
      sorter: {
        compare: (a, b) => a.feePerProduct - b.feePerProduct,
        multiple: 2
      }
    },
    {
      title: 'Fee Cap',
      dataIndex: 'feeCap',
      key: 'feeCap',
      render: value => `$${value}`,
      sorter: {
        compare: (a, b) => a.feeCap - b.feeCap,
        multiple: 3
      }
    },
    {
      title: 'Min Fee',
      dataIndex: 'minPlatformFee',
      key: 'minPlatformFee',
      render: value => `$${value}`,
      sorter: {
        compare: (a, b) => a.minPlatformFee - b.minPlatformFee,
        multiple: 4
      }
    },
    {
      title: 'Fee on $0',
      dataIndex: 'applyPlatformFeeOnZeroDollarOrder',
      key: 'applyPlatformFeeOnZeroDollarOrder',
      render: renderBooleanTag
    },
    {
      title: 'Inc Stripe Fee',
      dataIndex: 'includeStripeFee',
      key: 'includeStripeFee',
      render: renderBooleanTag
    },
    {
      title: 'Interactive Maps',
      dataIndex: 'interactiveMaps',
      key: 'interactiveMaps',
      render: renderBooleanTag
    },
    {
      title: 'Interactive Maps For Renters',
      dataIndex: 'interactiveMapsForRenters',
      key: 'interactiveMapsForRenters',
      render: renderBooleanTag
    },
    {
      title: 'Text Message Indicator',
      dataIndex: 'textMessageIndicator',
      key: 'textMessageIndicator',
      render: renderBooleanTag
    },
    {
      title: 'Show Assignments',
      dataIndex: 'showAssignments',
      key: 'showAssignments',
      render: renderBooleanTag
    },
    {
      title: 'Card Reader Payments',
      dataIndex: 'allowCardReaderPayments',
      key: 'allowCardReaderPayments',
      render: renderBooleanTag
    },
    {
      title: 'Required Map Assignments For Renters',
      dataIndex: 'requiredMapAssignmentsForRenters',
      key: 'requiredMapAssignmentsForRenters',
      render: renderBooleanTag
    },
    {
      title: 'Extra Charges Enabled',
      dataIndex: 'extraChargesEnabled',
      key: 'extraChargesEnabled',
      render: renderBooleanTag
    },
    {
      title: 'Acct Report',
      dataIndex: ['venueConfig', 'accountingReport'],
      key: 'accountingReport',
      render: renderBooleanTag
    },
    {
      title: 'Recon Report',
      dataIndex: ['venueConfig', 'reconReport'],
      key: 'reconReport',
      render: renderBooleanTag
    },
    {
      title: 'Req Addons',
      dataIndex: ['venueConfig', 'requiredAddons'],
      key: 'requiredAddons',
      render: renderBooleanTag
    },
    {
      title: 'Past Events',
      dataIndex: ['venueConfig', 'includePastEvents'],
      key: 'includePastEvents',
      render: renderBooleanTag
    }
  ];

  useEffect(() => {
    if (venuesData?.venues) {
      const sortedVenues = venuesData.venues.slice().sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });
      const venuesWithUpdatedEvents = sortedVenues.map(venue => {
        const sortedEvents = venue.events.slice().sort((a, b) => {
          return parseInt(b.id) - parseInt(a.id);
        });
        const eventsWithVenue = sortedEvents.map(event => ({
          ...event,
          venue: venue.name,
          venueId: venue.id,
          timeZone: venue.timeZone
        }));

        return {
          ...venue,
          events: eventsWithVenue
        };
      });
      setVenuesList(venuesWithUpdatedEvents);
      const allEvents = venuesWithUpdatedEvents.reduce((acc, venue) => acc.concat(venue.events), []);
      setAllEvents(allEvents);
    }
  }, [venuesData]);

  useEffect(() => {
    if (selectedVenue) {
      const now = new Date();
      let nextStartingEvent = null;
      let nextBookingOpenEvent = null;

      for (const event of selectedVenue.events) {
        const eventStart = new Date(event.startDate);
        const eventOpen = new Date(event.openDate);

        if (eventStart > now && (nextStartingEvent === null || eventStart < new Date(nextStartingEvent.startDate))) {
          nextStartingEvent = event;
        }

        if (eventOpen > now && (nextBookingOpenEvent === null || eventOpen < new Date(nextBookingOpenEvent.openDate))) {
          nextBookingOpenEvent = event;
        }
      }

      setNextStartingEvent(nextStartingEvent);
      setNextBookingOpening(nextBookingOpenEvent);
    }
  }, [selectedVenue]);

  const handleSelectVenue = venue => {
    setSelectedVenue(venue);
  };

  const rowClassName = record => {
    return selectedVenue && record.key === +selectedVenue.id ? 'venues-table-row clicked-row' : 'venues-table-row';
  };

  const handleClearSelection = () => {
    setSelectedVenue(null);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleRangeTransReport = () => {
    if (!selectedVenue?.id || !startDate || !endDate) return;

    setLoadingReport(true);
    const builtBody = {
      reportType: Report.TRANSACTION,
      venueId: selectedVenue.id,
      userId: user.id,
      eventIds: [],
      start: startDate,
      end: endDate
    };

    const token = Auth.getToken();
    const PENDING_MESSAGE = 'too large';
    const payload = JSON.parse(JSON.stringify(builtBody));

    fetch(`${API_URL}/admin/reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        auth: token ? `Bearer ${token}` : '',
        token: API_GATEWAY_TOKEN
      },
      mode: 'cors',
      body: JSON.stringify(payload)
    })
      .then(async response => {
        if (response.status === 204) {
          messageApi.open({
            type: 'error',
            content: 'No records found'
          });
          throw new Error('');
        } else if (response.status === 202) {
          return await response.text();
        } else return response.blob();
      })
      .then(blob => {
        if (typeof blob === 'string' && blob.includes(PENDING_MESSAGE)) {
          messageApi.open({
            type: 'warning',
            content: blob
          });
        } else
          download(
            blob,
            `${selectedVenue.name} - ${Report.TRANSACTION}_report - ${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}.xlsx`
          );
      })
      .catch(err => {
        messageApi.open({
          type: 'error',
          content: 'There was an error generating the report, or no records were found'
        });
        console.log(err);
      })
      .finally(() => {
        setLoadingReport(false);
      });
  };

  if (venuesError) return <p>Error: {venuesError.message}</p>;

  return (
    <section className={`${className} venues-info-section`}>
      {contextHolder}
      <FlexWrapper>
        <HeadingOne label="VENUES & EVENTS" className="" />
      </FlexWrapper>
      <div className="container">
        <div className="table-actions">
          <span onClick={handleClearSelection}>Clear selection</span>
        </div>
        <div className="venues-table">
          <Table
            columns={columns}
            bordered
            title={() => <strong>VENUES</strong>}
            dataSource={!venuesList ? [] : venuesList.map(venue => ({ ...venue, key: +venue.id }))}
            scroll={{ x: 'max-content' }}
            pagination={{ position: ['bottomCenter'], pageSize: 10, showSizeChanger: false }}
            loading={loading}
            rowClassName={rowClassName}
            onRow={venue => {
              return {
                onClick: () => {
                  handleSelectVenue(venue);
                }
              };
            }}
          />
        </div>
        <div className="calendar-wrapper">
          <span>Filter by Start and End dates: </span>
          <span>
            <Calendar
              allowSingleDate
              selectableRangeStart={'2019-10-30'}
              startDatePlaceholder={'START'}
              endDatePlaceholder={'END'}
              rangeEndWithoutLimits={true}
              plural={true}
              cb={({ startDate, endDate }) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              styles={{
                colorBgContainer: '#f1f4f7',
                colorBorder: 'transparent'
              }}
              className=""
              disabled={false}
              defaultValue={[startDate, endDate]}
              defaultTime={null}
              status=""
              label=""
              isFilter={false}
              placeholder=""
              selectableRangeEnd={undefined}
            />
            <span>
              <span className="clear-dates" onClick={handleClearDates}>
                clear
              </span>
            </span>
          </span>
        </div>
        <div className="venue-information">
          <div className="venue-container">
            {!selectedVenue && <div className="click-venue">Click on a venue to view more details.</div>}
            {selectedVenue && (
              <div className="selected-venue">
                <h1>{selectedVenue.name}</h1>
                <div>
                  <p>
                    <strong>Next event starts: </strong>
                    {nextStartingEvent
                      ? `${nextStartingEvent.name} - ${moment(nextStartingEvent.startDate)
                          .tz(nextStartingEvent.timeZone)
                          .format('MM/DD/YYYY')}`
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>Next booking opens: </strong>
                    {nextBookingOpening
                      ? `${nextBookingOpening.name} - ${moment(nextBookingOpening.openDate)
                          .tz(nextBookingOpening.timeZone)
                          .format('MM/DD/YYYY')}`
                      : 'N/A'}
                  </p>
                  <div>
                    <span>Transaction report: </span>
                    <span>
                      <Button disabled={!startDate || !endDate} loading={loadingReport} type="primary" onClick={handleRangeTransReport}>
                        Download
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <Events
              venueName={selectedVenue?.name}
              allEvents={allEvents}
              events={selectedVenue?.events}
              loading={loading}
              messageApi={messageApi}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const SuperAdminVenues = styled(Venues)`
  .container {
    margin-top: 50px;
  }
  .table-actions {
    width: 100%;
    flex-direction: row-reverse;
    display: flex;
    justify-items: right;
    align-items: center;
    height: 30px;
    margin: 20px 0;
    padding: 0 20px;
    color: #2875c3;
    span {
      cursor: pointer;
    }
  }

  .clear-dates {
    color: #2875c3;
    cursor: pointer;
  }

  .venues-table {
    margin-bottom: 50px;
  }

  .click-venue {
    margin-bottom: 30px;
    font-size: 20px;
  }

  .calendar-wrapper {
    margin-bottom: 20px;
  }

  .venues-table-row.clicked-row {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid darkorange;
      pointer-events: none;
    }

    .ant-table-cell-fix-left {
      border: 2px solid darkorange;
    }
  }

  .selected-venue {
    padding: 30px 0;
    font-size: 20px;
  }
`;
const FlexWrapper = styled.div`
  ${displayFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default compose(withRouter, withSnackbarContextActions)(memo(SuperAdminVenues));
