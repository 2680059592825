import React, { useEffect, useState, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid, Checkbox } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Field, useFormikContext } from 'formik';
import { isMobile } from 'react-device-detect';

import { TextField } from 'Components/Fields';
import { FormSelect } from 'Components/Select';
import { HeadingFive, HeadingFour } from 'Components/Headings';
import { Separator } from 'Components/Separator';
import BackDateWarning from './BackDateWarning';
import { formatOptionName, isEmpty } from 'Helpers';
import { RESERVATION_STATUSES } from 'Queries/Admin/ReservationsStatuses';
import colors from 'Styles/Colors';
import { paragraphReg } from 'Styles/Typography';
import ProductCard from './ProductCard';
import RVSelection from './RVSelection';
import RVProductSelect from '../../shared/RvProductSelect';
import { initialValues } from '../Create';
import { hasProductQuantityError } from 'Helpers/productLimits';
import IncrementerIcon from 'Components/NumberIncrementer/IncrementerIcon';
import { QuestionAnswers } from '../../shared/ProductQuestionsCard';
import useLengthValidation from '../../shared/useLengthValidation';
import { useValidateAction } from 'Utils/actions';
import { actions } from 'Constants/actions';
import { UserContext } from 'Store/UserContext';
import { ADMIN, RESERVATION_ADMIN } from 'Constants/userRoles';
import Calendar from 'Components/Calendar/Calendar';
import { ReloadOutlined } from '@ant-design/icons';

const RvSpotsBase = props => {
  const { className, setRVsOpen, isStallsOpen, setRvQuestionsAreValid } = props;
  const { errors, values, setFieldValue, setValues } = useFormikContext();
  const { event, reservationEdit, rv_spot, stalls } = values;
  const [isOpen, setIsOpen] = useState(false);
  const [useSameDates, setUseSameDates] = useState(false);
  const updateStatus = useLengthValidation(event?.rvQuestions, setRvQuestionsAreValid);
  const { user } = useContext(UserContext);
  const isVenueAdmin = +user.role.id === ADMIN;
  const isReservationdmin = +user.role.id === RESERVATION_ADMIN;
  const isRoleValid = isVenueAdmin || isReservationdmin;
  const mapsEnabled = event?.venue?.interactiveMaps;
  const maps = event?.venue?.maps;

  const { end, start } = rv_spot;
  const { end: stallsEnd, start: stallsStart } = stalls;

  const rvSelectionRef = useRef(null);
  const [isRVDataLoading, setIsRVDataLoading] = useState(false);

  useEffect(() => {
    setRVsOpen(isOpen);
    if (!isOpen) {
      setValues({
        ...values,
        rv_spot: initialValues.rv_spot,
        rvProductId: initialValues.rvProductId,
        selectedRvs: initialValues.selectedRvs
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isStallsOpen) setUseSameDates(false);
  }, [isStallsOpen]);

  useEffect(() => {
    if (useSameDates) {
      setDateRange({ startDate: stallsStart, endDate: stallsEnd });
    }
  }, [stallsStart, stallsEnd]);

  // PRELOAD SVG MAPS
  useEffect(() => {
    if (mapsEnabled && maps?.length > 0) {
      maps.forEach(map => fetch(map.url));
    }
  }, [mapsEnabled, maps]);

  const { data: reservationStatusesData, loading } = useQuery(RESERVATION_STATUSES);
  let reservationStatuses = [];
  if (reservationStatusesData) {
    reservationStatuses = reservationStatusesData.reservationStatuses.reduce((acc, status) => {
      if (Number(status.id) !== 4) {
        acc.push({ value: status.id, label: formatOptionName(status.name) });
      }
      return acc;
    }, []);
  }

  const today = moment().startOf('days');
  const isBackDateSelected = values.rv_spot.start?.isBefore(today);
  const eventHasStarted = moment().isAfter(moment(event.startDate).startOf('days'));

  if (loading) {
    return <></>;
  }

  const setDateRange = dates => {
    setFieldValue('rv_spot', {
      ...values.rv_spot,
      start: dates.startDate,
      end: dates.endDate
    });
  };

  const getStatusName = statuses => {
    if (isEmpty(rv_spot)) return '';
    return statuses.find(option => option.value === rv_spot.status) || null;
  };

  const statusName = getStatusName(reservationStatuses);

  const statusOptions = {
    cb: e => setFieldValue('rv_spot.status', e.target.value),
    key: !!statusName && !!statusName.value ? statusName.value : null,
    label: 'STATUS',
    options: reservationStatuses,
    selectedOption: rv_spot && rv_spot.status ? rv_spot.status : '1',
    type: 'select',
    value: getStatusName(reservationStatuses)
  };

  const disableDateSelection = isStallsOpen ? !rv_spot || reservationEdit || useSameDates || hasProductQuantityError(true, errors) : false;

  const quantityChange = async newValue => {
    let value = newValue;
    await setFieldValue('rv_spot.quantity', Number(value) <= 0 ? '' : value.replace(/[^0-9]/g, ''));
  };

  const setIncludeRVsSwitch = isOpenValue => {
    setIsOpen(isOpenValue);
    setFieldValue('isBelowMinNights.rvs', false);

    if (!isOpenValue) {
      setFieldValue('hasEmptyRVQuestions', {});
    }
  };

  const handleRefreshClick = () => {
    if (rvSelectionRef.current) {
      rvSelectionRef.current.refetchRVs();
    } else {
      console.warn('RVSelection ref not available yet.');
    }
  };

  const canChangeRvsStatus = useValidateAction('orders', actions.STALL_STATUS_DROPDOWN_ON_RESERVATION_SIDEBAR);
  const canAssignRvs = useValidateAction('orders', actions.PRODUCT_ASSIGNMENT);

  return (
    <RvSpotsCard
      isMobile={isMobile}
      className={`${className} ${isMobile ? 'mobile' : ''} ${isRVDataLoading ? 'loading' : ''}`}
      edit={reservationEdit}
      event={event}
      isOpen={isOpen}
      productType={'rvProduct'}
      setIsOpen={setIncludeRVsSwitch}>
      <div className={`card-content ${isOpen ? 'open' : ''}`}>
        {isOpen ? (
          <>
            <Separator margin="0.25rem 0 1.375rem" />
            <Grid container className="qty-and-date">
              <Grid item direction="column" md={6} className="quantity-column">
                <HeadingFive label={"Number of RV's"} className="" />
                <Field name={'rv_spot.quantity'}>
                  {({ field, meta }) => (
                    <div className="quantity-field-wrapper">
                      <IncrementerIcon
                        increment={async () => {
                          let value = Number(field.value) + 1;
                          quantityChange(value.toString());
                        }}
                        decrement={async () => {
                          let value = Number(field.value) - 1;
                          quantityChange(value.toString());
                        }}
                        top={29}
                        right={13}
                      />
                      <TextField
                        {...field}
                        {...meta}
                        autoComplete={'numberOfRvs'}
                        className="rvs-field"
                        disabled={isEmpty(event) || reservationEdit}
                        error={Boolean(meta.error)}
                        helperText={!!meta.error && meta.error}
                        inputProps={{ maxLength: 3 }}
                        InputLabelProps={{ shrink: field.value > 0 }}
                        label={'NUMBER OF SPOTS'}
                        onChange={e => quantityChange(e.target.value)}
                        type={'number'}
                        variant={'filled'}
                      />
                    </div>
                  )}
                </Field>
              </Grid>
              <Grid item direction="column" md={6} className="date-column">
                <div className="date-title--wrapper">
                  <HeadingFive label={'Dates'} />
                  {isStallsOpen && (
                    <div className="item checkbox_container" style={hasProductQuantityError(true, errors) ? { opacity: 0.5 } : {}}>
                      <span>Use same dates as my stall reservation</span>
                      <Checkbox
                        color="primary"
                        name="useResDates"
                        onClick={e => {
                          setUseSameDates(e.target.checked);
                          if (e.target.checked) {
                            setDateRange({ startDate: values.stalls.start, endDate: values.stalls.end });
                          } else {
                            setDateRange({ startDate: null, endDate: null });
                          }
                        }}
                        disabled={hasProductQuantityError(true, errors)}
                        type="checkbox"
                      />
                    </div>
                  )}
                </div>
                <div className={`date-input--wrapper ${values.isBelowMinNights.rvs && 'error'}`}>
                  <Calendar
                    selectableRangeStart={moment(event.startDate).format('YYYY-MM-DD')}
                    selectableRangeEnd={event.endDate}
                    startDatePlaceholder={'CHECK IN'}
                    endDatePlaceholder={'CHECK OUT'}
                    plural={true}
                    defaultValue={[start, end]}
                    disabled={disableDateSelection}
                    backDate={isRoleValid ? moment(event.startDate).format('YYYY-MM-DD') : null}
                    cb={({ startDate, endDate }) => {
                      setDateRange({ startDate, endDate });
                    }}
                    styles={{
                      colorBgContainer: '#f1f4f7',
                      colorBorder: 'transparent'
                    }}
                  />
                  <span className="error-msg">Min Nights is {values.selectedRVMinNights}</span>
                </div>
                {isBackDateSelected && <BackDateWarning />}
              </Grid>
            </Grid>
            {canChangeRvsStatus && (
              <>
                <HeadingFive label={'RV Spot Reservation Status'} className="spacing-top" />
                <FormSelect disabled={!!errors.rv_spot && !!errors.rv_spot.quantity} className="status-options" {...statusOptions} />
              </>
            )}
            <HeadingFour label={'RV Spot Type'} className="spacing-bottom" />
            <RVProductSelect className={className} noLayout allowBackDateQuery={isBackDateSelected && eventHasStarted} />
            {canAssignRvs && (
              <>
                <div className="rv-assignment-wrapper">
                  <HeadingFour label={'RV Spot Assignment'} />
                  {isRoleValid && !!values.rvProductId && (
                    <span title="Refresh" onClick={handleRefreshClick} className="refresh-icon-wrapper">
                      <ReloadOutlined className={isRVDataLoading ? 'spinning' : ''} style={{ fontSize: '20px', color: '#2875c3' }} />
                    </span>
                  )}
                </div>
                {!!values.rvProductId && <RVSelection ref={rvSelectionRef} onLoadingChange={setIsRVDataLoading} mapsEnabled={mapsEnabled} maps={maps} />}
                {event.rvQuestions.length > 0 && (
                  <>
                    <Separator margin="20px 0" />
                    <HeadingFour label={'Additional RV Info'} />
                  </>
                )}
              </>
            )}
            {event.rvQuestions.map((question, index) => (
              <>
                <p className={question.questionType === 'openText' ? 'open-text' : ''}>
                  {question.question}
                  {question.required && <span className="required-text"> (Required)</span>}
                </p>
                <QuestionAnswers question={question} productType="rvs" updateStatus={updateStatus} index={index} />
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </RvSpotsCard>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RvSpotsCard = styled(ProductCard)`
  .spacing-top {
    margin-top: 20px !important;
  }

  .spacing-bottom {
    margin-bottom: 20px !important;
  }

  .qty-and-date {
    align-items: center;
  }

  &.mobile {
    .qty-and-date {
      flex-direction: column;
    }

    .quantity-column {
      padding-right: 0;
      width: 100%;
    }

    .date-column {
      padding-left: 0;
      width: 100%;
    }

    .date-title--wrapper {
      flex-direction: column;
    }
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${colors.white};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.button.primary.active};
    opacity: 1;
  }

  .status-options {
    ${paragraphReg}
    color: rgb(0, 0, 0);
    font-size: 0.9375rem;
    line-height: 24px;
    margin: 10px 0 20px;
    padding: 13px 0 0;
    background-color: ${colors.background.primary} !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &::placeholder {
      color: ${colors.text.secondary};
    }

    &--disabled {
      opacity: 0.5;
    }

    label[class^='MuiInputLabel-formControl'],
    label[class*='MuiInputLabel-formControl'] {
      top: -13px !important;
    }

    svg[class^='MuiSelect-icon'],
    svg[class*='MuiSelect-icon'] {
      top: -2px;
    }

    div[class^='MuiSelect-selectMenu'],
    div[class*='MuiSelect-selectMenu'] {
      margin-bottom: 15px;
    }
  }

  .item.checkbox_container {
    span {
      font-size: 12px;
    }

    .MuiCheckbox-root {
      padding: 0 0 0 9px;
    }
  }

  .date-title--wrapper {
    display: flex;
    justify-content: space-between;

    h5 {
      margin-bottom: 18px !important;
    }
  }

  .date-input--wrapper {
    height: 56px;
    margin-bottom: 26px;

    .calendar-picker {
      width: 100%;
      height: 100%;
      margin-top: 5px;
    }

    .error-msg {
      display: none;
      color: #f44336;
      margin-top: 13px;
    }

    &.error {
      border-bottom: 2px solid #f44336;

      .error-msg {
        display: block;
      }
    }
  }

  .quantity-column {
    padding-right: 10px;
  }

  .date-column {
    padding-left: 10px;
  }

  .DateInput_input__disabled {
    font-style: normal;
  }

  .quantity-field-wrapper {
    position: relative;
  }

  .rv-assignment-wrapper {
    display: flex;
    align-items: center;

    .refresh-icon-wrapper {
      cursor: pointer;
      margin-left: 10px;
      line-height: 0;
      display: inline-flex;
    }

    .spinning {
      animation: ${spin} 1s linear infinite;
    }
  }
`;

export default RvSpotsBase;
