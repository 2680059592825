import gql from 'graphql-tag';

export const VENUES_COMPLETE = gql`
  query Venues {
    venues {
      id
      name
      phone
      street
      street2
      city
      state
      zip
      timeZone
      createdAt
      description
      stripeAccount
      stripeAccountType
      platformFee
      percentageFee
      feePerProduct
      feeCap
      minPlatformFee
      applyPlatformFeeOnZeroDollarOrder
      includeStripeFee
      interactiveMaps
      interactiveMapsForRenters
      textMessageIndicator
      showAssignments
      allowCardReaderPayments
      requiredMapAssignmentsForRenters
      extraChargesEnabled
      venueConfig {
        accountingReport
        reconReport
        requiredAddons
        includePastEvents
      }
      buildings {
        id
        name
      }
      rvLots {
        id
        name
        description
        sewer
        water
        power
      }
      addOns {
        id
        name
        unitName
        description
      }
      events {
        id
        name
        startDate
        endDate
        description
        checkInTime
        checkOutTime
        openDate
        closeDate
        createdAt
        protectedStartDate
        protectedEndDate
        isGroupCodeRequired
        allowAddOnOnlyOrder
        protectedEvent
        hidden
      }
    }
  }
`;
