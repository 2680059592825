import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';

import EventDetails from './EventDetails';
import StallProductSelect from '../../shared/StallProductSelect';
import PaymentDetails from './PaymentDetails';
import AddOns from './AddOns';
import SpecialRequests from './SpecialRequests';
import RenterInformation from './RenterInformation';
import ReservationFlowLayout from './ReservationFlowLayout';
import useReservationFlowRoutes from './useReservationFlowRoutes';
import CardSectionHeader from '../../../../components/Cards/CardSectionHeader';
import DateSelectDetails from './DateSelectDetails';
import RvProductSelect from '../../shared/RvProductSelect';
import RV from '../../../../assets/img/icons/RV.png';
import HorseShoe from '../../../../assets/img/icons/Horseshoe.png';
import ReservationSummary from './ReservationSummary';
import ReservationBackButton from './ReservationBackButton';
import CheckoutSummaryPreview from './CheckoutSummaryPreview';
import ROUTES from '../../../../constants/routes';
import ProductQuestions from '../../shared/ProductQuestionsCard';
import PickSpotOnMapForRenter from '../../shared/PickSpotOnMapForRenter';
import CustomAuth from 'Lib/customAuth';
import { Card } from '../../../RenterEvents/EventCard';
import { displayFlex } from '../../../../styles/Mixins';

function ReservationForm(props) {
  const { user, elements, setIsZeroRatesForm, stripe } = props;
  const { stallsRoutePattern, checkoutRoutePattern, rvsRoutePattern } = useReservationFlowRoutes();
  const [, setStallIsBelowMinNights] = useState(false);
  const [, setRvIsBelowMinNights] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [stallQuestionsAreValid, setStallQuestionsAreValid] = useState(true);
  const [rvQuestionsAreValid, setRvQuestionsAreValid] = useState(true);

  const { push, goBack } = useHistory();

  const {
    values: { event }
  } = useFormikContext();

  useEffect(() => {
    // PASSWORD PROTECTION CHECK
    const venueTimezone = event && event.venue.timeZone ? event.venue.timeZone : null;
    const currentTime = moment()
      .tz(venueTimezone)
      .format('MM/DD/YYYY HH:mm:ss');

    if (event.protectedStartDate && event.protectedEndDate) {
      const protectedStart = moment(event.protectedStartDate)
        .tz(venueTimezone)
        .format('MM/DD/YYYY HH:mm:ss');
      const protectedEnd = moment(event.protectedEndDate)
        .tz(venueTimezone)
        .format('MM/DD/YYYY HH:mm:ss');
      const isProtected = moment(currentTime).isBetween(protectedStart, protectedEnd, undefined, '[]') && event.protectedEvent;
      if (isProtected) {
        const token = CustomAuth.getToken(`ae-${event.id}`);
        if (!token) {
          setIsNotAllowed(true);
          setTimeout(() => {
            goBack();
          }, 3000);
        } else {
          CustomAuth.clearToken(`ae-${event.id}`);
        }
      }
    }

    // BOOKING DATES CHECK
    const eventOpenDate = moment(event.openDate)
      .tz(venueTimezone)
      .format('MM/DD/YYYY HH:mm:ss');
    const eventCloseDate = moment(event.closeDate)
      .tz(venueTimezone)
      .format('MM/DD/YYYY HH:mm:ss');

    let isNotOpenYet = moment(currentTime).isBefore(eventOpenDate);
    let isClosed = moment(currentTime).isAfter(eventCloseDate);

    if (isNotOpenYet || isClosed) {
      push(ROUTES.ROOT);
    }

    // PRELOAD MAPS
    if (event?.venue?.interactiveMaps && event?.venue?.interactiveMapsForRenters && event?.venue?.maps?.length > 0) {
      event.venue.maps.forEach(map => fetch(map.url));
    }
  }, [event]);

  const hasStallProducts = event.stallProducts.length > 0;

  const getStallSection = () => {
    if (hasStallProducts || !event.allowAddOnOnlyOrder) {
      return (
        <>
          <CardSectionHeader text={`Book Stalls ${event.stallSoldOut ? ' (SOLD OUT)' : ''}`} headerIcon={HorseShoe} />
          <DateSelectDetails key={'stalls'} type={'stalls'} setIsBelowMinNights={setStallIsBelowMinNights} soldOut={event.stallSoldOut} />
          <StallProductSelect />
          <PickSpotOnMapForRenter type="barn" />
          {event.stallQuestions.length > 0 && (
            <ProductQuestions productType="stalls" questions={event.stallQuestions} setProductQuestionsAreValid={setStallQuestionsAreValid} />
          )}
        </>
      );
    }
  };

  const getRvSection = () => {
    return (
      <>
        <CardSectionHeader text="Book RV Spots" headerIcon={RV} />
        <DateSelectDetails key={'rvs'} type={'rvs'} setIsBelowMinNights={setRvIsBelowMinNights} soldOut={event.rvSoldOut} />
        <RvProductSelect />
        <PickSpotOnMapForRenter type="lot" />
        {event.rvQuestions.length > 0 && (
          <ProductQuestions productType="rvs" questions={event.rvQuestions} setProductQuestionsAreValid={setRvQuestionsAreValid} />
        )}
      </>
    );
  };

  return (
    <ReservationFlowLayout
      backButton={<ReservationBackButton />}
      rightColumn={
        <ReservationSummary
          user={user}
          elements={elements}
          stripe={stripe}
          setIsZeroRatesForm={setIsZeroRatesForm}
          stallQuestionsAreValid={stallQuestionsAreValid}
          rvQuestionsAreValid={rvQuestionsAreValid}
          stallSoldOut={event.stallSoldOut}
          rvSoldOut={event.rvSoldOut}
        />
      }>
      <Switch>
        <Route path={stallsRoutePattern} exact>
          <EventDetails />
          {getStallSection()}
          <AddOns label="ADD ONS" />
        </Route>

        <Route path={rvsRoutePattern} exact>
          <EventDetails />
          {getRvSection()}
          <AddOns label="ADD ONS" />
        </Route>

        <Route path={checkoutRoutePattern} exact>
          <RenterInformation user={user} />
          <PaymentDetails user={user} elements={elements} isGroupCodeRequired={event && event.isGroupCodeRequired} event={event} />
          <SpecialRequests />
          <CheckoutSummaryPreview />
        </Route>
      </Switch>
      <NotAllowedModal
        open={isNotAllowed}
        onClose={() => {
          goBack();
        }}
        disableAutoFocus={true}
        disableRestoreFocus={true}
        className="not-allowed-modal">
        <FlexWrapper>
          <Card className="not-allowed-card" data-testid="not-allowed">
            <div className="modal-message">YOU ARE NOT ALLOWED</div>
          </Card>
        </FlexWrapper>
      </NotAllowedModal>
    </ReservationFlowLayout>
  );
}

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;

  &:first-child {
    margin: 0 0 30px 0;
  }

  @media screen and (max-width: 601px) {
    ${displayFlex}
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &:first-child {
      margin: 0 0 30px 0;
    }
  }
`;

const NotAllowedModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  .not-allowed-card {
    flex-direction: column;
    display: flex;
    height: auto !important;
  }

  .modal-message {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
  }

  div[aria-hidden='true'] {
    backdrop-filter: blur(5px);
  }

  .not-allowed-modal {
    & {
      div[class^='MuiCard-root'],
      div[class*='MuiCard-root'] {
        max-width: 540px;
        font-size: 16px;
      }
      .MuiPaper-root.MuiCard-root {
        h4 {
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
`;

export default ReservationForm;
