import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Add, Clear } from '@material-ui/icons';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import CheckboxThemed from '../Checkbox';
import colors from '../../../../styles/Colors';
import { HeadingFour } from 'Components/Headings';
import { checkIfFlipIsValid, getLabel } from './flipHelper';
import { Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const checkboxChangeHandlerHelper = (stallsPerBuilding, stallsForThisRate, setFieldValue, index, buildingId) => e => {
  if (!stallsPerBuilding) return;
  if (e.target.checked) {
    const { availableStalls: stallsToMap } = stallsPerBuilding.find(b => b.id === buildingId) || { availableStalls: [] };
    const stallsToPush = stallsToMap.map(stall => stall.id);
    const s = new Set(stallsForThisRate.concat(stallsToPush));
    setFieldValue(`stalls[${index}].stallsForThisRate`, Array.from(s));
  } else {
    if (!stallsForThisRate.length) return;
    const { availableStalls: stallsToMap } = stallsPerBuilding.find(b => b.id === buildingId) || { availableStalls: [] };
    const stallsToRemove = stallsToMap.map(stall => stall.id);
    const s = new Set(stallsToRemove);
    const updatedList = stallsForThisRate.filter(stallId => !s.has(stallId));
    setFieldValue(`stalls[${index}].stallsForThisRate`, updatedList);
  }
};

const stallClickHandlerHelper = (isSelected, stall, stallsForThisRate, setFieldValue, index, stallHasBeenBooked) => e => {
  e.preventDefault();
  if (!stallHasBeenBooked) {
    if (isSelected) {
      const s = new Set(stallsForThisRate);
      s.delete(stall.id);
      setFieldValue(`stalls[${index}].stallsForThisRate`, Array.from(s));
      setFieldValue(`stallsRemoved`, true);
    } else {
      const updatedStallsArray = [...stallsForThisRate, stall.id];
      setFieldValue(`stalls[${index}].stallsForThisRate`, updatedStallsArray);
      setFieldValue(`stallsRemoved`, false);
    }
  }
};

const StallSelectionBase = ({ className, stallsPerBuilding, index, buildingsWithFlipping, selectedBuildingsOverlapping, noSelectableBuildingsId }) => {
  const { values, setFieldValue } = useFormikContext();
  const { stalls: formikStateStallsProp, hasStalls } = values;
  const singleProduct = values['stalls'].length ? values['stalls'][index] : '';
  const stats = singleProduct?.stats;
  const { stallsForThisRate, bookedStalls } = formikStateStallsProp[index];
  const disableSelectAll = !stallsPerBuilding || !hasStalls || stallsForThisRate.length === 0;
  const deSelectAll = event => {
    event.preventDefault();
    setFieldValue(`stalls[${index}].stallsForThisRate`, values.stalls[index].bookedStalls ? [...values.stalls[index].bookedStalls] : []);
  };

  const selectAll = event => {
    event.preventDefault();
    const allStalls =
      stallsPerBuilding &&
      stallsPerBuilding.reduce((acc, building) => {
        acc = acc.concat(building.availableStalls.map(stall => stall.id));
        return acc;
      }, []);
    setFieldValue(`stalls[${index}].stallsForThisRate`, allStalls);
  };

  const hasValidFlip = buildingId => {
    return checkIfFlipIsValid(buildingsWithFlipping, buildingId, singleProduct, noSelectableBuildingsId);
  };

  useEffect(() => {
    if (stats && stats.sold > 0) {
      // Determine minimum required stalls based on flip status
      const minimumRequired = stats.flip ? stats.maxDailyOccupancy : stats.sold;

      // Set error state based on whether we have enough stalls
      const hasError = stallsForThisRate.length < minimumRequired;

      setFieldValue('integrityStallDataErrors', {
        ...values.integrityStallDataErrors,
        [stats.id]: hasError
      });
    }
  }, [stallsForThisRate.length]);

  let isPossibleOverbooking = false;
  if (stats && stats.sold > 0) {
    // Determine minimum required spots based on flip status
    const minimumRequired = stats.flip ? stats.maxDailyOccupancy : stats.sold;

    // Set error state based on whether we have enough spots
    isPossibleOverbooking = stallsForThisRate.length < minimumRequired;
  }

  return (
    <div className={className}>
      <div className="heading-wrapper">
        <span>
          {isPossibleOverbooking && (
            <span className="possible-overbooking" style={{ marginBottom: '20px', marginRight: '10px' }}>
              <Tooltip
                placement="topRight"
                title={'Warning! This product has fewer stalls selected than sold reservations. Add more stalls to avoid overbooking.'}>
                <WarningOutlined style={{ color: 'red', fontSize: '20px' }} />
              </Tooltip>
            </span>
          )}
          <HeadingFour label={`Stalls for this Rate (${stallsForThisRate.length})`} className="" />
        </span>
        <span className="buttons-wrapper">
          <Button className={disableSelectAll ? 'disabled-button' : 'active-button'} onClick={deSelectAll} disabled={disableSelectAll}>
            DESELECT ALL
          </Button>
          <Button
            className={!stallsPerBuilding || !hasStalls ? 'disabled-button' : 'active-button'}
            onClick={selectAll}
            disabled={!stallsPerBuilding || !hasStalls}>
            SELECT ALL
          </Button>
        </span>
      </div>
      <AccordionStyled>
        {stallsPerBuilding &&
          stallsPerBuilding.map(building => {
            const { id: buildingId, name, availableStalls: unsortedStalls } = building;
            const stalls = unsortedStalls.sort((a, b) => (!!a && !!b ? a.name.localeCompare(b.name, 'en', { numeric: true }) : 0));
            const totalPossible = stalls.length;
            const numSelected = stalls.filter(stall => !!stallsForThisRate.find(v => v === stall.id)).length;
            const key = `${buildingId}-${name}`;
            const labelText = `${name.toUpperCase()} (${numSelected}/${totalPossible})`;
            const label = getLabel(buildingsWithFlipping, buildingId, labelText, selectedBuildingsOverlapping, index, singleProduct.id);
            const checked = numSelected === totalPossible;
            const indeterminate = numSelected !== 0 && numSelected < totalPossible;
            const checkboxChangeHandler = checkboxChangeHandlerHelper(stallsPerBuilding, stallsForThisRate, setFieldValue, index, buildingId);
            const hasStallAssigned = singleProduct.initialStallsForThisRate
              ? building.availableStalls.filter(availableStall => {
                  const stall = singleProduct.initialStallsForThisRate.find(bookedStall => bookedStall === availableStall.id);
                  if (stall) {
                    return true;
                  }

                  return false;
                })
              : [];
            const flipIsValid = hasValidFlip(buildingId);
            return (
              <Accordion key={key} disabled={!hasStalls}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand" aria-controls="additional-actions1-content" id={`building-${buildingId}`}>
                  <FormControlLabel
                    className="building-name-accordion"
                    aria-label="Acknowledge"
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <CheckboxThemed
                        onChange={checkboxChangeHandler}
                        checked={checked}
                        indeterminate={indeterminate}
                        disabled={!hasStalls || (checked && hasStallAssigned.length && singleProduct.booked) || !flipIsValid}
                      />
                    }
                    label={label}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ul className="stall-selection-per-building">
                      {stalls.map(stall => {
                        const stallHasBeenBooked = bookedStalls ? bookedStalls.some(bookedStall => bookedStall === stall.id) : false;
                        const isSelected = stallsForThisRate.find(stallId => stallId === stall.id);
                        const selectedClass = isSelected ? ' selected ' : '';
                        const disabledClass = stallHasBeenBooked || !flipIsValid ? ' disabled ' : '';
                        const classNames = `${selectedClass} ${disabledClass}`;
                        const clickHandler = stallClickHandlerHelper(isSelected, stall, stallsForThisRate, setFieldValue, index, stallHasBeenBooked);

                        return (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                          <li key={`stall-${stall.id}`} className={`stall-listing ${classNames}`} onClick={clickHandler}>
                            <span className="stall-name">{stall.name}</span>
                            {(isSelected && singleProduct?.booked) || isSelected ? !stallHasBeenBooked ? <Clear /> : null : <Add />}
                          </li>
                        );
                      })}
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </AccordionStyled>
    </div>
  );
};

const StallSelection = styled(StallSelectionBase)`
  & {
    display: block;
    margin: 0;
    padding: 0;

    .heading-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h4 {
        display: inline-flex;
        margin-bottom: 10px;
      }
    }

    .buttons-wrapper {
      display: inline-flex;
      transform: translate(10px, 0px);
    }

    .active-button {
      letter-spacing: 1.5px;
      color: ${colors.secondary};
      margin-top: -3px;
      border-bottom: #ffffff solid 1px;
    }

    .disabled-button {
      letter-spacing: 1.5px;
      color: ${colors.text.lightGray2};
      margin-top: -3px;
      border-bottom: #ffffff solid 1px;
    }
  }
`;

const StallsAccordionWrapper = ({ className, children }) => <div className={`${className} stalls-accordion-wrapper`}>{children}</div>;

const AccordionStyled = styled(StallsAccordionWrapper)`
  .building-name-accordion {
    span.MuiFormControlLabel-label {
      color: inherit !important;
    }
  }
  .stall-selection-per-building {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    width: inherit;
    margin: 0;
    padding: 0 0 0 20px;
    justify-content: flex-start;

    li {
      cursor: pointer;
      display: inline-flex;
      width: 22%;
      height: 42px;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 3px;
      justify-content: space-between;
      background-color: ${colors.background.primary};
      border: 1px solid ${colors.text.lightGray2};
      color: ${colors.text.lightGray2};

      &.selected {
        background-color: ${colors.secondary};
        border: none;
        color: white;
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: auto;
      }

      .stall-name {
        padding: 10px 0 0 10px;
        user-select: none;
      }

      .MuiSvgIcon-root {
        transform: translate(-5px, 10px);
        transition: 0s ease-in, color 0s;
      }
    }
  }

  &.stalls-accordion-wrapper {
    border-radius: 5px;
    margin-top: -12px;
  }

  &&& {
    .MuiAccordion-root:before {
      background: none;
    }

    .MuiPaper-root {
      transition: none;
      margin: 0;
      background-color: ${colors.background.primary};
    }

    .MuiPaper-root:first-child {
      margin-top: 12px;
    }

    .MuiPaper-root:last-child {
      margin-bottom: 12px;
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordionDetails-root {
      padding: 0 16px;
    }

    .MuiTypography-root {
      width: 100%;
      margin-bottom: 0;
    }

    .MuiCheckbox-indeterminate {
      color: ${colors.secondary};
    }
  }
`;

export default StallSelection;
