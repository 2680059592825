/* global API_GATEWAY_TOKEN */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Space, Table, Tag } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import download from 'downloadjs';
import Auth from 'Lib/auth';
import { Report } from 'Constants/reportType';
import { UserContext } from 'Store/UserContext';

const Events = ({ venueName, events, loading, allEvents, messageApi, startDate, endDate }) => {
  const { user } = useContext(UserContext);
  const [loadingReport, setLoadingReport] = useState(null);
  const searchInput = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL || '';
  const [venueEvents, setVenueEvents] = useState(events || []);
  const [fullEventsList, setFullEventsList] = useState(allEvents || []);

  useEffect(() => {
    if (startDate && endDate) {
      // Filter events that fall within the date range
      const filteredEvents = events?.filter(event => {
        // Check if event's date range overlaps with the selected date range
        return moment(event.startDate).isSameOrBefore(endDate) && moment(event.endDate).isSameOrAfter(startDate);
      });

      // Filter all events with the same logic
      const filteredAllEvents = allEvents?.filter(event => {
        return moment(event.startDate).isSameOrBefore(endDate) && moment(event.endDate).isSameOrAfter(startDate);
      });

      setVenueEvents(filteredEvents);
      setFullEventsList(filteredAllEvents);
    } else {
      // If no date range is selected, use the original lists
      setVenueEvents(events);
      setFullEventsList(allEvents);
    }
  }, [events, allEvents, startDate, endDate]);

  const handleSearch = (_, confirm) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      }
    },
    render: text => text
  });

  const reportsOptions = [
    {
      label: 'Transaction Report',
      key: Report.TRANSACTION
    },
    {
      label: 'Event Report',
      key: Report.EVENT
    },
    {
      label: 'Stall & Add-on Report',
      key: Report.STALL
    },
    {
      label: 'RV Report',
      key: Report.RV
    },
    {
      label: 'Reservation Report',
      key: Report.RESERVATION
    }
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
      fixed: 'left'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      fixed: 'left'
    },
    {
      title: 'Venue',
      dataIndex: 'venue',
      key: 'venue',
      ...getColumnSearchProps('venue')
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: (date, event) =>
        moment(date)
          .tz(event.timeZone)
          .format('MM/DD/YYYY')
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
      render: date => moment(date).format('MM/DD/YYYY')
    },
    {
      title: 'Open Booking Date',
      dataIndex: 'openDate',
      key: 'openDate',
      sorter: (a, b) => new Date(a.openDate) - new Date(b.openDate),
      render: (date, event) =>
        moment(date)
          .tz(event.timeZone)
          .format('MM/DD/YYYY hh:mm A')
    },
    {
      title: 'Close Booking Date',
      dataIndex: 'closeDate',
      key: 'closeDate',
      sorter: (a, b) => new Date(a.closeDate) - new Date(b.closeDate),
      render: (date, event) =>
        moment(date)
          .tz(event.timeZone)
          .format('MM/DD/YYYY hh:mm A')
    },
    {
      title: 'Check-In Time',
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      sorter: (a, b) => new Date(a.checkInTime) - new Date(b.checkInTime),
      render: (time, event) =>
        moment(time, 'HH:mm:ss')
          .tz(event.timeZone)
          .format('hh:mm A')
    },
    {
      title: 'Check-Out Time',
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      sorter: (a, b) => new Date(a.checkOutTime) - new Date(b.checkOutTime),
      render: (time, event) =>
        moment(time, 'HH:mm:ss')
          .tz(event.timeZone)
          .format('hh:mm A')
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: date => moment(date).format('MM/DD/YYYY')
    },
    {
      title: 'Protected Start Date',
      dataIndex: 'protectedStartDate',
      key: 'protectedStartDate',
      sorter: (a, b) => new Date(a.protectedStartDate) - new Date(b.protectedStartDate),
      render: (date, event) =>
        date
          ? moment(date)
              .tz(event.timeZone)
              .format('MM/DD/YYYY hh:mm A')
          : 'N/A'
    },
    {
      title: 'Protected End Date',
      dataIndex: 'protectedEndDate',
      key: 'protectedEndDate',
      sorter: (a, b) => new Date(a.protectedEndDate) - new Date(b.protectedEndDate),
      render: (date, event) =>
        date
          ? moment(date)
              .tz(event.timeZone)
              .format('MM/DD/YYYY hh:mm A')
          : 'N/A'
    },
    {
      title: 'Group Code Required',
      dataIndex: 'isGroupCodeRequired',
      key: 'isGroupCodeRequired',
      render: flag => (flag ? <Tag color="green">ON</Tag> : <Tag color="red">OFF</Tag>)
    },
    {
      title: 'Add-On Only Order',
      dataIndex: 'allowAddOnOnlyOrder',
      key: 'allowAddOnOnlyOrder',
      render: flag => (flag ? <Tag color="green">ON</Tag> : <Tag color="red">OFF</Tag>)
    },
    {
      title: 'Protected Event',
      dataIndex: 'protectedEvent',
      key: 'protectedEvent',
      render: flag => (flag ? <Tag color="green">ON</Tag> : <Tag color="red">OFF</Tag>)
    },
    {
      title: 'Hidden',
      dataIndex: 'hidden',
      key: 'hidden',
      render: flag => (flag ? <Tag color="green">ON</Tag> : <Tag color="red">OFF</Tag>)
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, event) => {
        return (
          <Dropdown menu={{ items: reportsOptions, onClick: e => handleGetReport(e, event) }} trigger={['click']}>
            <Button loading={loadingReport === event.id}>
              Get Report <DownOutlined />
            </Button>
          </Dropdown>
        );
      }
    }
  ];

  const handleGetReport = (option, event) => {
    setLoadingReport(event.id);
    const builtBody = {
      reportType: option.key,
      venueId: event.venueId,
      userId: user.id,
      eventIds: [event.id],
      start: null,
      end: null
    };

    const token = Auth.getToken();
    const PENDING_MESSAGE = 'too large';
    const payload = JSON.parse(JSON.stringify(builtBody));

    fetch(`${API_URL}/admin/reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        auth: token ? `Bearer ${token}` : '',
        token: API_GATEWAY_TOKEN
      },
      mode: 'cors',
      body: JSON.stringify(payload)
    })
      .then(async response => {
        if (response.status === 204) {
          messageApi.open({
            type: 'error',
            content: 'No records found'
          });
          throw new Error('');
        } else if (response.status === 202) {
          return await response.text();
        } else return response.blob();
      })
      .then(blob => {
        if (typeof blob === 'string' && blob.includes(PENDING_MESSAGE)) {
          messageApi.open({
            type: 'warning',
            content: blob
          });
        } else download(blob, `${event.name} - ${event.venue} - ${option.key}_report.xlsx`);
      })
      .catch(err => {
        messageApi.open({
          type: 'error',
          content: 'There was an error with the report'
        });
        console.log(err);
      })
      .finally(() => {
        setLoadingReport(null);
      });
  };

  const dateRangeLabel = startDate && endDate ? `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}` : '';

  return (
    <Table
      title={() => <strong>{venueName ? `${venueName}'s events ${dateRangeLabel}` : `EVENTS ${dateRangeLabel}`}</strong>}
      dataSource={venueEvents?.length > 0 ? venueEvents : fullEventsList}
      columns={columns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      bordered
      loading={loading}
      pagination={{ position: ['bottomCenter'], pageSize: 10, showSizeChanger: false }}
    />
  );
};

export default React.memo(Events);
