import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'recompose';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getValueByPropPath } from 'Utils/objectHelpers';
import { ORDERS_TABLE } from 'Queries/Admin/OrdersTable';
import { subRouteCodes as SUB_ROUTES } from '../../../../constants/routes';
import AddButton from '../../../../components/Button/AddButton';
import InfiniteTable from '../../../../components/Table/InfiniteTable';
import IndeterminateLoading from '../../../../components/Loading/IndeterminateLoading';
import { UserContext } from 'Store/UserContext';
import TableFilters from './TableFilters';
import { useOrderSidePanel, ViewOrderSidePanel } from '../ViewOrderSidePanel';
import ContextSnackbar from '../../../../components/Snackbar';
import { TableContextProvider } from 'Components/Table/TableContext';
import ActionBar from '../../../../components/Table/ActionBar/ActionBar';
import { withSnackbarContextActions } from 'Store/SnackbarContext';
import { withUserContext } from 'Store/UserContext';
import { HeadingOne } from 'Components/Headings';
import { ADMIN, GROUP_LEADER, RESERVATION_ADMIN } from 'Constants/userRoles';

/**
 * Get just the orders collection from the result, or return []
 * @returns Array<object>
 */
const getDataFromResult = data => {
  return getValueByPropPath(data, 'orders.orders', []);
};

const CREATE_ORDER_LINK_MAP = {
  [ADMIN]: SUB_ROUTES.ADMIN.CREATE_ORDER,
  [GROUP_LEADER]: SUB_ROUTES.GROUP_LEADER.CREATE_ORDER,
  [RESERVATION_ADMIN]: SUB_ROUTES.ADMIN.CREATE_ORDER
};

const OrdersTableBase = () => {
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filters, setFilters] = useState({ reservationStatus: 0 });
  const [ordering, setOrdering] = useState('updatedAt_DESC');
  const [openFilters, setOpenFilters] = useState(false);
  const [checkInOutFilter, setCheckInOutFilter] = useState({
    checkInOnly: false,
    checkOutOnly: false
  });
  const { open, onClose, orderID } = useOrderSidePanel();

  const { user } = useContext(UserContext);

  useEffect(() => {
    const sessionSortOrder = sessionStorage.getItem('reservationsOrder');
    if (!sessionSortOrder) sessionStorage.setItem('reservationsOrder', ordering);
    else if (ordering !== sessionSortOrder) setOrdering(sessionSortOrder);
  }, [ordering]);

  useEffect(() => {
    initialLoad ? localStorage.setItem('selectedRows', '') : null;
  }, [initialLoad]);

  const limit = 25;
  const { data, fetchMore, loading } = useQuery(ORDERS_TABLE, {
    variables: {
      input: {
        limit,
        filterBy: filters,
        orderBy: ordering,
        offset: 1,
        checkInOnly: checkInOutFilter.checkInOnly,
        checkOutOnly: checkInOutFilter.checkOutOnly
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [getAllOrders, { data: allOrders }] = useLazyQuery(ORDERS_TABLE, {
    variables: {
      input: {
        filterBy: filters,
        orderBy: ordering,
        offset: 1,
        limit: 1000,
        checkInOnly: checkInOutFilter.checkInOnly,
        checkOutOnly: checkInOutFilter.checkOutOnly
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const error = getValueByPropPath(data, 'orders.error');
  if (error) {
    // TODO - handle error
    return <div>{error}</div>;
  }

  const orders = getDataFromResult(data);

  const itemCount = hasMoreRecords ? orders.length + 1 : orders.length;

  const isItemLoaded = index => !hasMoreRecords || index < orders.length;

  const loadMoreItems = () => {
    setInitialLoad(false);
    if (!hasMoreRecords || data.orders.orders.length % limit) {
      return;
    }
    return fetchMore({
      variables: {
        input: {
          limit,
          filterBy: filters,
          orderBy: ordering,
          offset: data.orders.orders.length / limit + 1,
          checkInOnly: checkInOutFilter.checkInOnly,
          checkOutOnly: checkInOutFilter.checkOutOnly
        }
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setHasMoreRecords(false);
          return prev;
        }
        const newData = getDataFromResult(fetchMoreResult);
        if (newData.length === 0) {
          setHasMoreRecords(false);
          return prev;
        }
        const previousData = getDataFromResult(prev);
        if (!newData || previousData === newData) return prev;
        const newResult = {
          orders: {
            error: fetchMoreResult.orders.error,
            orders: [...previousData, ...newData],
            success: fetchMoreResult.orders.success,
            __typename: 'OrdersReturn'
          }
        };

        return newData.length > 0 ? newResult : prev;
      }
    });
  };

  const CREATE_ORDER_LINK = CREATE_ORDER_LINK_MAP[+user.role.id];

  const handleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };

  return (
    <OrdersTable isMobile={isMobile}>
      <ContextSnackbar />
      <div className="page-header">
        <div className="header-wrapper">
          <HeadingOne label="RESERVATIONS &amp; ORDERS" />
          <div>
            <CreateReservationLink to={CREATE_ORDER_LINK}>
              <AddButtonBase isMobile={isMobile} label={isMobile ? '' : 'CREATE NEW'} styles={isMobile ? { width: '44px', height: '40px' } : {}} />
            </CreateReservationLink>
          </div>
        </div>
      </div>
      <>
        <div className="main-wrapper">
          <TableContextProvider>
            <TableFilters
              setCheckInOutFilter={setCheckInOutFilter}
              orders={orders}
              checkInOutFilter={checkInOutFilter}
              filters={filters}
              setFilters={setFilters}
              open={openFilters}
              closeFilters={() => setOpenFilters(false)}
            />
            <ViewOrderSidePanel onClose={onClose} orderID={orderID} orders={orders} />
            {!(orders.length === 0) ? (
              <div className="column-wrapper">
                <ActionBar
                  count={data?.orders?.count || 0}
                  checkingInCount={data?.orders?.checkingInCount || 0}
                  checkingOutCount={data?.orders?.checkingOutCount || 0}
                  ordering={ordering}
                  setOrdering={setOrdering}
                  checkInOutFilter={checkInOutFilter}
                  setCheckInOutFilter={setCheckInOutFilter}
                  filters={filters}
                  getAllOrders={getAllOrders}
                  allOrders={allOrders}
                  loading={loading}
                  user={user}
                  openFilters={handleOpenFilters}
                />
                <InfiniteTable
                  orderID={orderID}
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  items={orders}
                  openRow={open}
                />
              </div>
            ) : !loading ? (
              <div className="column-wrapper empty-list">
                <ActionBar
                  count={data?.orders?.count || 0}
                  checkingInCount={data?.orders?.checkingInCount || 0}
                  checkingOutCount={data?.orders?.checkingOutCount || 0}
                  ordering={ordering}
                  setOrdering={setOrdering}
                  checkInOutFilter={checkInOutFilter}
                  setCheckInOutFilter={setCheckInOutFilter}
                  filters={filters}
                  getAllOrders={getAllOrders}
                  allOrders={allOrders}
                  loading={loading}
                  user={user}
                  openFilters={handleOpenFilters}
                />
                <h4>No results found</h4>
              </div>
            ) : (
              <div className="empty-list">
                <IndeterminateLoading />
              </div>
            )}
          </TableContextProvider>
        </div>
      </>
    </OrdersTable>
  );
};

const OrdersTable = styled.div`
  height: 100%;

  .page-header {
    text-align: left;
    border-bottom: 1px solid rgb(200, 214, 229);
    &&& {
      margin: 0;
    }
  }

  .empty-list {
    width: calc(100% - 300px);

    ${({ isMobile }) =>
      isMobile &&
      `
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 100%;
    `}

    h4 {
      width: 100px;
      margin: 30px auto 0 auto;

      ${({ isMobile }) =>
        isMobile &&
        `
        margin-top: 50px;
    `}
    }
  }

  .header-wrapper {
    margin: 85px 22px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin: 0;
      padding: 14px 16px;
      min-height: 100px;

      h1 {
        line-height: 35px !important;
      }
    `}
  }

  .main-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    &&& {
      margin: 0;
    }
  }

  .column-wrapper {
    width: calc(100% - 300px);
    height: 100%;

    ${({ isMobile }) =>
      isMobile &&
      `
      width: 100%;
    `}

    &&& {
      margin: 0;
    }
  }

  .list-helper {
    flex: 1 1 auto;
  }

  .list-container {
    display: flex;
  }
`;

const AddButtonBase = styled(AddButton)`
  &&& {
    margin-left: 20px;

    ${({ isMobile }) =>
      isMobile &&
      `
      margin: 0;
    `}

    svg {
      position: relative;
    }
  }
`;

const CreateReservationLink = styled(Link)`
  text-decoration: none;
`;

export default compose(withUserContext, withSnackbarContextActions)(OrdersTableBase);
