import React, { memo, useState, useEffect, useRef } from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
const { RangePicker } = DatePicker;
const isBetween = require('dayjs/plugin/isBetween');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(isBetween);

const useHasChanged = val => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// Using ant design Range picker: https://ant.design/components/date-picker
const Calendar = ({
  allowSingleDate,
  className,
  disabled,
  showTime = false,
  plural = true,
  rangeEndWithoutLimits = false,
  backDate = null,
  defaultValue,
  defaultTime,
  status,
  label,
  cb,
  placeholder,
  startDatePlaceholder,
  endDatePlaceholder,
  selectableRangeStart,
  selectableRangeEnd,
  styles,
  allowClear = false
}) => {
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const defaultValueHasChanged = useHasChanged(defaultValue);

  const _disabledDate = (current, startDate, endDate) => {
    // runs this function for dates shown one by one
    // startDate and endDate in YYYY-MM-DD format. Using 'day' granularity and
    // including start and end dates. See more here: https://day.js.org/docs/en/plugin/is-between
    const isNotInsideRange = !dayjs(current).isBetween(backDate ? backDate : startDate, endDate, 'day', '[]');
    const isTheSameSelectedDate = dates && ((dates[0] && current.isSame(dates[0], 'day')) || (dates[1] && current.isSame(dates[1], 'day')));

    return isNotInsideRange || (!allowSingleDate && isTheSameSelectedDate);
  };

  const _onChange = value => {
    setValue(value);

    if (dayjs(value).isValid() && cb && !plural) {
      const date = moment(dayjs(value).format('YYYY-MM-DD'));

      return cb(date);
    }
  };

  const _onCalendarChange = value => {
    setDates(value);
    if (value.length === 2 && dayjs(value[0]).isValid() && dayjs(value[1]).isValid() && cb) {
      // Start and End dates were selected

      const startDate = moment(dayjs(value[0]).format('YYYY-MM-DD'));
      const endDate = moment(dayjs(value[1]).format('YYYY-MM-DD'));

      let openingTime, closingTime;

      if (showTime) {
        openingTime = dayjs(value[0]).format('HH:mm');
        closingTime = dayjs(value[1]).format('HH:mm');
      }

      return cb({
        startDate,
        endDate,
        openingTime,
        closingTime
      });
    }
  };

  const _setDefaultValue = () => {
    if (defaultValue) {
      const date = moment(defaultValue).format('YYYY-MM-DD');

      if (showTime && defaultTime) {
        return dayjs(`${date} ${defaultTime}`, 'YYYY-MM-DD HH:mm');
      }

      return dayjs(date, 'YYYY-MM-DD');
    }

    return null;
  };

  const _setDefaultRange = () => {
    if (defaultValue && defaultValue.length === 2 && defaultValue[0] && defaultValue[1]) {
      const firstDate = moment(defaultValue[0]).format('YYYY-MM-DD');
      const secondDate = moment(defaultValue[1]).format('YYYY-MM-DD');

      if (showTime && defaultTime) {
        return [dayjs(`${firstDate} ${defaultTime[0]}`, 'YYYY-MM-DD HH:mm'), dayjs(`${secondDate} ${defaultTime[1]}`, 'YYYY-MM-DD HH:mm')];
      }

      return [dayjs(firstDate, 'YYYY-MM-DD'), dayjs(secondDate, 'YYYY-MM-DD')];
    }

    return null;
  };

  // Handle default value changes for range pickers when sending new values from
  // Parent components. e.g. "Entire event" check
  useEffect(() => {
    if (defaultValue && defaultValue[0] && defaultValue[1] && defaultValueHasChanged && !showTime && plural) {
      if (dates && dates.length === 2) {
        const sDate = dayjs(dates[0]).format('YYYY-MM-DD');
        const eDate = dayjs(dates[1]).format('YYYY-MM-DD');

        if (sDate !== defaultValue[0] || eDate !== defaultValue[1]) {
          const newDates = [dayjs(defaultValue[0]), dayjs(defaultValue[1])];
          setValue(newDates);
          setDates(newDates);
        }
      }
    }
  }, [defaultValue]);

  let rangeStart = dayjs().format('YYYY-MM-DD'); // Today
  let rangeEnd = dayjs('2030-12-31').format('YYYY-MM-DD'); // Without limits

  if (selectableRangeStart) {
    rangeStart = dayjs(selectableRangeStart).format('YYYY-MM-DD');
  }

  if (selectableRangeEnd && !rangeEndWithoutLimits) {
    rangeEnd = dayjs(selectableRangeEnd).format('YYYY-MM-DD');
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          /*
            Here is your component tokens: https://ant.design/components/date-picker#design-token
            See more: https://ant.design/docs/react/customize-theme
          */
          colorPrimary: 'rgb(16, 172, 132)'
        },
        components: {
          DatePicker: {
            hoverBorderColor: 'rgb(76, 193, 163)',
            controlItemBgActive: 'rgba(76, 193, 163, .29)',
            cellRangeBorderColor: 'rgb(76, 193, 163)',
            cellHoverWithRangeBg: 'rgb(76, 193, 163)',
            cellActiveWithRangeBg: 'rgb(76, 193, 163)',
            activeBorderColor: 'rgb(76, 193, 163)',
            colorPrimary: 'rgb(16, 172, 132)',
            colorLink: 'rgb(16, 172, 132)',
            colorLinkHover: 'rgb(76, 193, 163)',
            borderRadius: 4,
            zIndexPopup: 9999,
            ...(styles && { colorBorder: styles.colorBorder ? styles.colorBorder : 'rgba(0, 0, 0, 0.87)' }),
            ...(styles && { colorBgContainer: styles.colorBgContainer ? styles.colorBgContainer : '#fff' })
          }
        }
      }}>
      {!plural && (
        <DatePicker
          className={`calendar-picker${className ? ` ${className}` : ''}`}
          popupClassName={`calendar-picker-popup--datepicker ${isMobile ? 'mobile' : ''}`}
          format={showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
          placeholder={placeholder || label || undefined}
          allowClear={allowClear}
          disabled={!!disabled || false}
          disabledDate={val => _disabledDate(val, rangeStart, rangeEnd)}
          value={dates || value || _setDefaultValue()}
          status={status || ''}
          onChange={_onChange}
          onCalendarChange={_onCalendarChange}
          showTime={showTime ? { format: 'HH:mm' } : undefined}
        />
      )}
      {plural && (
        <RangePicker
          className={`calendar-picker${className ? ` ${className}` : ''}`}
          popupClassName={`calendar-picker-popup--rangepicker ${isMobile ? 'mobile' : ''} ${showTime ? 'with-time' : ''}`}
          format={showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
          allowClear={allowClear}
          disabled={!!disabled || false}
          placeholder={[startDatePlaceholder ? startDatePlaceholder : 'FROM', endDatePlaceholder ? endDatePlaceholder : 'TO']}
          disabledDate={val => _disabledDate(val, rangeStart, rangeEnd)}
          value={dates || value || _setDefaultRange()}
          status={status || ''}
          onChange={_onChange}
          onCalendarChange={_onCalendarChange}
          showTime={showTime ? { format: 'HH:mm' } : undefined}
        />
      )}
    </ConfigProvider>
  );
};

export default memo(Calendar);
