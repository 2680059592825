import gql from 'graphql-tag';

export const EVENT_FOR_ORDER_CREATE = gql`
  query EventForOrderCreate($eventId: ID) {
    event(id: $eventId) {
      id
      rvSoldOut
      stallSoldOut
      name
      description
      startDate
      endDate
      protectedStartDate
      protectedEndDate
      protectedEvent
      hidden
      checkInTime
      checkOutTime
      openDate
      closeDate
      allowAddOnOnlyOrder
      stallQuestions {
        id
        question
        questionType
        answerOptions
        required
        minLength
        maxLength
      }
      rvQuestions {
        id
        question
        questionType
        answerOptions
        required
        minLength
        maxLength
      }
      venue {
        city
        description
        id
        name
        phone
        state
        street
        street2
        timeZone
        zip
        interactiveMaps
        interactiveMapsForRenters
        requiredMapAssignmentsForRenters
        textMessageIndicator
        allowCardReaderPayments
        stripeLocation
        buildings {
          id
          name
        }
        rvLots {
          id
          name
        }
        maps {
          id
          name
          type
          url
          buildings
        }
        venueConfig {
          requiredAddons
        }
      }
      stallProducts {
        description
        endDate
        id
        name
        nightly
        price
        startDate
        minNights
        hidden
      }
      addOnProducts {
        id
        price
        disabled
        addOn {
          id
          name
          description
          unitName
        }
      }
      requiredAddOns {
        id
        eventId
        addOnProduct
        xRefTypeId
        ratio
        condition
        max
      }
      rvProducts {
        id
        name
        description
        startDate
        endDate
        price
        nightly
        minNights
        rvLot {
          id
          name
          description
          sewer
          water
          power
        }
      }
      venueAgreement {
        id
        name
        url
      }
      venueMap {
        id
        name
        url
      }
      isGroupCodeRequired
    }
  }
`;
